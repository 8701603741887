import * as React from 'react';

import { PartialSpectrumCollectionNode } from '../../utils';
import { SelectActionProps } from './types';

// See examples of getCollectionNode https://github.com/adobe/react-spectrum/search?q=getCollectionNode
export const SelectAction = (_props: SelectActionProps): React.ReactElement => {
  return null;
};

export const isSelectAction = (item: unknown): item is SelectActionProps => {
  return item && typeof item === 'object' && item.hasOwnProperty('onPress') ? true : false;
};

SelectAction.getCollectionNode = function* getCollectionNode(
  props: SelectActionProps,
): Generator<PartialSpectrumCollectionNode<object>> {
  const { label } = props;

  const textValue = label || '';

  yield {
    type: 'item',
    key: label,
    props,
    rendered: textValue,
    textValue,
    'aria-label': props['aria-label'],
    hasChildNodes: false,
  };
};

import { FocusRing } from '@react-aria/focus';
import * as React from 'react';
import { memo } from 'react';

import { Box } from '../Box';
import { BoxOwnProps, IBoxHTMLAttributes } from '../Box/types';
import { Flex } from '../Flex';
import { AppearanceVals } from './types';

export interface IPanelTitlebar extends BoxOwnProps, Omit<IBoxHTMLAttributes, 'title'> {
  /**
   * The contents to render on the right side of the titlebar.
   *
   * This component will not toggle the collapsed state if clicked.
   */
  rightComponent?: React.ReactNode;

  /**
   * Titlebar background color.
   */
  bg?: 'canvas-100' | 'canvas-200' | 'canvas-300' | 'transparent';

  /**
   * The icon to render to the left of the titlebar children.
   *
   * If collapse is enabled, this prop will be overridden with a caret icon.
   */
  icon?: React.ReactNode;

  /**
   * Controls how the titlebar will appear.
   */
  appearance?: AppearanceVals;
}

const titlebarVariants: Record<AppearanceVals, Partial<BoxOwnProps>> = {
  default: {
    bg: 'canvas-200',
    borderColor: {
      default: 'input',
      focus: 'primary',
    },
    color: 'body',
  },
  minimal: {
    pos: 'relative',
    fontWeight: 'medium',
  },
  outlined: {
    borderColor: {
      default: 'input',
      focus: 'primary',
    },
  },
};

const iconVariants: Record<AppearanceVals, Partial<BoxOwnProps>> = {
  default: {
    mr: 1.5,
  },
  minimal: {
    pos: 'absolute',
    left: -4,
    color: 'body',
    fontWeight: 'normal',
  },
  outlined: {
    mr: 1.5,
  },
};

export const PanelTitlebar = memo<IPanelTitlebar>(
  ({ children, icon, appearance = 'default', rightComponent, onClick, bg, tabIndex, ...props }) => {
    const isPadded = appearance === 'default' || appearance === 'outlined';

    return (
      <FocusRing focusRingClass="sl-focus-ring">
        <Flex
          className="sl-panel__titlebar"
          pos="relative"
          zIndex={{ focus: 10 }}
          align="center"
          fontSize="base"
          userSelect="none"
          lineHeight="none"
          pl={isPadded ? (icon ? 3 : 4) : undefined}
          pr={isPadded ? (rightComponent ? 3 : 4) : undefined}
          {...titlebarVariants[appearance]}
          bg={bg || titlebarVariants[appearance].bg}
          {...props}
          tabIndex={tabIndex}
        >
          <Flex flex={1} align="center" h="lg" onClick={onClick}>
            {icon && (
              <Flex {...iconVariants[appearance]} align="center">
                {icon}
              </Flex>
            )}
            {children}
          </Flex>

          {rightComponent}
        </Flex>
      </FocusRing>
    );
  },
);

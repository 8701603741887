// Originally adapated from https://github.com/adobe/react-spectrum/tree/main/packages/%40react-stately/selection

import { useControlledState } from '@react-stately/utils';
import { MultipleSelection, SelectionMode } from '@react-types/shared';
import { Key, useCallback, useMemo, useRef } from 'react';
import create from 'zustand';
import shallow from 'zustand/shallow';

import { Selection } from './Selection';
import { MultipleSelectionObservableState, MultipleSelectionState } from './types';

/**
 * Manages state for multiple selection and focus in a collection.
 */
export function useMultipleSelectionState(
  props: MultipleSelection,
): MultipleSelectionState & { useIsFocusedKey: (key: Key) => boolean } {
  let { selectionMode = 'none' as SelectionMode, disallowEmptySelection } = props;

  // We want synchronous updates to `isFocused` and `focusedKey` after their setters are called.
  // But we also need to trigger a react re-render. So, we have both a ref (sync) and state (async).
  const isFocusedRef = useRef(false);
  const focusedKeyRef = useRef(null);
  const childFocusStrategyRef = useRef(null);
  const selectedKeysProp = useMemo(() => convertSelection(props.selectedKeys), [props.selectedKeys]);
  const defaultSelectedKeys = useMemo(
    () => convertSelection(props.defaultSelectedKeys, new Selection()),
    [props.defaultSelectedKeys],
  );
  const [selectedKeys, setSelectedKeys] = useControlledState(
    selectedKeysProp,
    defaultSelectedKeys,
    props.onSelectionChange,
  );
  const disabledKeysProp = useMemo(
    () => (props.disabledKeys ? new Set(props.disabledKeys) : new Set<Key>()),
    [props.disabledKeys],
  );

  const useStore = useRef(
    create<MultipleSelectionObservableState>(set => ({
      isFocused: false,
      focusedKey: null,
      setFocused: f =>
        set(state => {
          isFocusedRef.current = f;
          return {
            ...state,
            isFocusedRef: f,
          };
        }),
      setFocusedKey: (k, childFocusStrategy = 'first') =>
        set(state => {
          focusedKeyRef.current = k;
          childFocusStrategyRef.current = childFocusStrategy;
          return { ...state, focusedKey: k };
        }),
    })),
  );

  const [setFocused, setFocusedKey] = useStore.current(state => [state.setFocused, state.setFocusedKey], shallow);
  function useIsFocusedKey(key: Key) {
    const cb = useCallback(state => state.focusedKey === key, [key]);
    if (!useStore.current) return false;
    return useStore.current(cb);
  }

  function useFocusedKey() {
    const cb = useCallback(state => state.focusedKey, []);
    if (!useStore.current) return false;
    return useStore.current(cb);
  }

  return {
    useIsFocusedKey,
    useFocusedKey,
    selectionMode,
    disallowEmptySelection,
    get isFocused() {
      return isFocusedRef.current;
    },
    setFocused,
    get focusedKey() {
      return focusedKeyRef.current;
    },
    get childFocusStrategy() {
      return childFocusStrategyRef.current;
    },
    setFocusedKey,
    selectedKeys,
    setSelectedKeys,
    disabledKeys: disabledKeysProp,
  };
}

function convertSelection(selection: 'all' | Iterable<Key>, defaultValue?: Selection): 'all' | Selection {
  if (!selection) {
    return defaultValue;
  }

  return selection === 'all' ? 'all' : new Selection(selection);
}

import { Pressable } from '@react-aria/interactions';
import cn from 'clsx';
import * as React from 'react';

import { AspectRatio, AspectRatioProps } from '../AspectRatio';
import { Box } from '../Box';
import { BoxOwnProps } from '../Box/types';
import { Modal, useModalState } from '../Modal';
import { ImageProps } from './Image';

export type ProductImageFocusVals = 'top' | 'bottom' | 'center' | 'top-right' | 'top-left';

export type ProductImageOwnProps = {
  focus?: ProductImageFocusVals;
  caption?: string;
};

export type ProductImageProps = BoxOwnProps & ProductImageOwnProps;

export function ProductImage({ className, children, focus, caption, bg = 'success', ...props }: ProductImageProps) {
  const { isOpen, open, close } = useModalState();

  // enforce single child
  const child = React.Children.only(children) as React.ReactElement;

  const focusContainerProps = FocusVariants[focus]?.container || {};
  const focusAspectProps = FocusVariants[focus]?.aspect || {};
  const focusImageProps = FocusVariants[focus]?.image || {};
  const focusCaptionProps = FocusVariants[focus]?.caption || {};

  const imageElem = React.cloneElement<ImageProps>(child, {
    bg: 'canvas-pure',
    borderColor: 'body',
    overflowX: 'hidden',
    overflowY: 'hidden',
    mx: 'auto',
    ...focusImageProps,
    style: Object.assign(focusImageProps.style || {}, child.props.style || {}),
  });

  let elem = imageElem;
  if (focusAspectProps.ratio) {
    elem = (
      <AspectRatio mx="auto" ratio={1} {...focusAspectProps}>
        {imageElem}
      </AspectRatio>
    );
  }

  const resolvedCaption = caption || child.props.title;
  let captionElem;
  if (resolvedCaption) {
    if (focus) {
      captionElem = (
        <Box
          as="figcaption"
          display="block"
          style={{ color: 'white' }}
          pb={8}
          mt={-8}
          mx="auto"
          px={20}
          fontWeight="semibold"
          fontSize="paragraph"
          textAlign="center"
          {...focusCaptionProps}
        >
          {resolvedCaption}
        </Box>
      );
    } else {
      captionElem = <Box as="figcaption">{resolvedCaption}</Box>;
    }
  }

  return (
    <>
      <Pressable onPress={open}>
        <Box as="figure">
          <Box
            className={cn('sl-product-image', className)}
            border={2}
            borderColor="body"
            rounded="xl"
            bg={focus ? bg : undefined}
            overflowX="hidden"
            overflowY="hidden"
            transform
            cursor="zoom-in"
            transitionDuration={300}
            translateX={{ hover: 2 }}
            translateY={{ hover: -2 }}
            style={{
              '--shadow-md': '-8px 8px 0 0 var(--color-text)',
            }}
            boxShadow={{
              hover: true,
            }}
            {...props}
            {...focusContainerProps}
          >
            {focus && focus !== 'bottom' ? captionElem : null}

            {elem}

            {focus && focus === 'bottom' ? captionElem : null}
          </Box>

          {!focus ? captionElem : null}
        </Box>
      </Pressable>

      <Modal isOpen={isOpen} onClose={close} size="grow">
        <Box as={Pressable} onPress={close} cursor="zoom-out" overflowX="hidden" overflowY="hidden" rounded="lg">
          <Box>
            {React.cloneElement<ImageProps>(child, {
              style: Object.assign({ maxHeight: 800 }, child.props.style || {}),
            })}
          </Box>
        </Box>
      </Modal>
    </>
  );
}

const FocusVariants: Record<
  ProductImageFocusVals,
  {
    container?: BoxOwnProps;
    aspect?: Partial<AspectRatioProps>;
    image?: Partial<ImageProps>;
    caption?: BoxOwnProps;
  }
> = {
  center: {
    container: { p: 16 },
    image: {
      border: 2,
      rounded: 'xl',
      boxShadow: 'lg',
      style: { maxHeight: 500 },
    },
  },
  bottom: {
    container: { pb: 16 },
    aspect: { ratio: 16 / 9, mx: 16 },
    image: {
      borderB: 2,
      borderL: 2,
      borderR: 2,
      rounded: 'b-lg',
      objectFit: 'scale-down',
      objectPosition: 'bottom',
    },
    caption: {
      pb: 0,
      mt: 0,
      pt: 8,
      mb: -8,
    },
  },
  top: {
    container: { pt: 16 },
    aspect: { ratio: 16 / 9, mx: 16 },
    image: {
      borderT: 2,
      borderL: 2,
      borderR: 2,
      rounded: 't-lg',
      objectFit: 'scale-down',
      objectPosition: 'top',
    },
  },
  'top-right': {
    container: { pt: 16 },
    aspect: { ratio: 16 / 9, mr: 16 },
    image: {
      borderT: 2,
      borderR: 2,
      rounded: 'tr-lg',
      objectFit: 'scale-down',
      objectPosition: 'left-top',
    },
  },
  'top-left': {
    container: { pt: 16 },
    aspect: { ratio: 16 / 9, ml: 16 },
    image: {
      borderT: 2,
      borderL: 2,
      rounded: 'tl-lg',
      objectFit: 'scale-down',
      objectPosition: 'right-top',
    },
  },
};

import { FontSizeVals, HeightVals, IntentVals, NegativeSpaceVals, RoundedVals, SpaceVals } from '../../enhancers';
import { BoxOwnProps } from '../Box/types';

export type AppearanceVals = 'default' | 'primary' | 'minimal';

export const sizes: Partial<
  Record<
    HeightVals,
    {
      px: SpaceVals;
      fontSize: FontSizeVals;
      rounded: RoundedVals;
      iconSize?: FontSizeVals;
      leftIconMx: SpaceVals | NegativeSpaceVals;
      leftIconMr: SpaceVals | NegativeSpaceVals;
      rightIconMl: SpaceVals | NegativeSpaceVals;
      rightIconMr: SpaceVals | NegativeSpaceVals;
    }
  >
> = {
  md: {
    px: 2.5,
    fontSize: 'base',
    rounded: true,
    iconSize: 'xs',
    leftIconMx: -1,
    leftIconMr: 1.5,
    rightIconMl: 1.5,
    rightIconMr: -0.5,
  },
  sm: {
    px: 1.5,
    fontSize: 'base',
    rounded: true,
    iconSize: 'xs',
    leftIconMx: 0,
    leftIconMr: 1,
    rightIconMl: 1,
    rightIconMr: -0.5,
  },
};

export const variants: Record<AppearanceVals, Record<IntentVals, Partial<BoxOwnProps>>> = {
  default: {
    default: {
      borderColor: 'button',
      bg: {
        default: 'canvas',
        hover: 'canvas-50',
        active: 'canvas-100',
      },
      opacity: {
        disabled: 60,
      },
    },
    success: {
      borderColor: 'success',
      ringColor: 'success',
    },
    warning: {
      borderColor: 'warning',
      ringColor: 'warning',
    },
    danger: {
      borderColor: 'danger',
      ringColor: 'danger',
    },
  },
  minimal: {
    default: {
      color: {
        default: 'muted',
        hover: 'body',
        focus: 'body',
      },
      bg: {
        hover: 'canvas-50',
        active: 'canvas-100',
      },
      borderColor: 'transparent',
      opacity: {
        disabled: 70,
      },
    },
    success: {
      color: 'success',
    },
    warning: {
      color: 'warning',
    },
    danger: {
      color: 'danger',
    },
  },
  primary: {
    default: {
      color: {
        default: 'on-primary',
        disabled: 'body',
      },
      bg: {
        default: 'primary',
        hover: 'primary-dark',
        active: 'primary-darker',
        disabled: 'canvas-100',
      },
      borderColor: 'transparent',
      opacity: {
        disabled: 70,
      },
    },
    success: {
      color: {
        default: 'on-success',
        disabled: 'body',
      },
      bg: {
        default: 'success',
        hover: 'success-dark',
        active: 'success-darker',
        disabled: 'canvas-100',
      },
    },
    warning: {
      color: {
        default: 'on-warning',
        disabled: 'body',
      },
      bg: {
        default: 'warning',
        hover: 'warning-dark',
        active: 'warning-darker',
      },
    },
    danger: {
      color: {
        default: 'on-danger',
        disabled: 'body',
      },
      bg: {
        default: 'danger',
        hover: 'danger-dark',
        active: 'danger-darker',
      },
    },
  },
};

export type FieldButtonAppearanceVals = 'minimal' | 'outlined';

export const fieldButtonVariants: Record<FieldButtonAppearanceVals, Record<IntentVals, Partial<BoxOwnProps>>> = {
  minimal: {
    default: {
      fontWeight: 'normal',
      color: {
        default: 'body',
      },
      ring: false,
      bg: {
        default: 'transparent',
      },
      borderColor: {
        default: 'transparent',
        hover: 'input',
        focus: 'primary',
        active: 'primary',
      },
    },
    success: {
      color: 'success',
      borderColor: {
        default: 'transparent',
        hover: 'success',
        focus: 'success',
      },
    },
    warning: {
      color: 'warning',
      borderColor: {
        default: 'transparent',
        hover: 'warning',
        focus: 'warning',
      },
    },
    danger: {
      color: 'danger',
      borderColor: {
        default: 'transparent',
        hover: 'danger',
        focus: 'danger',
      },
    },
  },
  outlined: {
    default: {
      borderColor: {
        default: 'input',
        hover: 'input',
        focus: 'primary',
        active: 'primary',
      },
    },
    success: {
      borderColor: {
        default: 'success',
        hover: 'success',
        focus: 'success',
      },
    },
    warning: {
      borderColor: {
        default: 'warning',
        hover: 'warning',
        focus: 'warning',
      },
    },
    danger: {
      borderColor: {
        default: 'danger',
        hover: 'danger',
        focus: 'danger',
      },
    },
  },
};

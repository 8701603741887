import { isMac } from '@react-aria/utils';
import { unstable_batchedUpdates } from 'react-dom';
import create from 'zustand';

/**
 * This is an adaptation of react-aria modality global listeners that takes advantage of zustand for much better
 * downstream peformance.
 */

type Modality = 'keyboard' | 'pointer' | 'virtual';
type ModalityStoreState = {
  currentModality: Modality | null;
  setInteractionModality: (modality: Modality) => void;
};
const useModalityStore = create<ModalityStoreState>(set => ({
  currentModality: null,
  setInteractionModality: modality =>
    set(state => {
      return {
        ...state,
        currentModality: modality,
      };
    }),
}));

export function useInteractionModality() {
  return useModalityStore(state => state.currentModality);
}

export function getInteractionModality() {
  return useModalityStore.getState().currentModality;
}

/**
 * Helper function to determine if a KeyboardEvent is unmodified and could make keyboard focus styles visible.
 */
function isValidKey(e: KeyboardEvent) {
  // Control and Shift keys trigger when navigating back to the tab with keyboard.
  return !(
    e.metaKey ||
    (!isMac() && e.altKey) ||
    e.ctrlKey ||
    (e.type === 'keyup' && (e.key === 'Control' || e.key === 'Shift'))
  );
}

function handleKeyboardEvent(e: KeyboardEvent) {
  if (isValidKey(e)) {
    const { currentModality } = useModalityStore.getState();
    if (currentModality !== 'keyboard') {
      unstable_batchedUpdates(() => {
        useModalityStore.getState().setInteractionModality('keyboard');
      });
    }
  }
}

function handlePointerEvent() {
  const state = useModalityStore.getState();
  if (state.currentModality !== 'pointer') {
    unstable_batchedUpdates(() => {
      useModalityStore.getState().setInteractionModality('pointer');
    });
  }
}

function isVirtualClick(event: MouseEvent | PointerEvent): boolean {
  // JAWS/NVDA with Firefox.
  if ((event as any).mozInputSource === 0 && event.isTrusted) {
    return true;
  }

  return event.detail === 0 && !(event as PointerEvent).pointerType;
}

function handleClickEvent(e: MouseEvent) {
  if (isVirtualClick(e)) {
    const state = useModalityStore.getState();
    if (state.currentModality !== 'virtual') {
      unstable_batchedUpdates(() => {
        useModalityStore.getState().setInteractionModality('virtual');
      });
    }
  }
}

let hasSetupGlobalListeners = false;
function setupGlobalFocusEvents() {
  if (typeof window === 'undefined' || hasSetupGlobalListeners) {
    return;
  }

  document.addEventListener('keydown', handleKeyboardEvent, true);
  document.addEventListener('keyup', handleKeyboardEvent, true);
  document.addEventListener('click', handleClickEvent, true);

  if (typeof PointerEvent !== 'undefined') {
    document.addEventListener('pointerdown', handlePointerEvent, true);
    document.addEventListener('pointermove', handlePointerEvent, true);
    document.addEventListener('pointerup', handlePointerEvent, true);
  } else {
    document.addEventListener('mousedown', handlePointerEvent, true);
    document.addEventListener('mousemove', handlePointerEvent, true);
    document.addEventListener('mouseup', handlePointerEvent, true);
  }

  hasSetupGlobalListeners = true;
}

if (typeof document !== 'undefined') {
  if (document.readyState !== 'loading') {
    setupGlobalFocusEvents();
  } else {
    document.addEventListener('DOMContentLoaded', setupGlobalFocusEvents);
  }
}

import * as React from 'react';

import { Cursor } from '../../enhancers';
import { MenuKeyboardDelegate } from './MenuKeyboardDelegate';
import { MenuState } from './useMenuState';

export type MenuContextProps = {
  state: MenuState;
  keyboardDelegate: MenuKeyboardDelegate;
  closeOnPress?: boolean;
  size?: 'lg' | 'default';
  cursor?: Cursor;
  onClose?: () => void;
};

export const MenuContext = React.createContext<MenuContextProps>(null);

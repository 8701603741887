import { useContext } from 'react';
import shallow from 'zustand/shallow';

import { InvertThemeContext } from '../components/InvertTheme';
import { useThemeStore } from './use-theme-store';

export const useThemeIsDark = () => {
  const { inverted } = useContext(InvertThemeContext);
  const [isLightBg, isLightInvertedBg] = useThemeStore(
    s => [s.colorValues.light, s.invertedColorValues.light],
    shallow,
  );

  if (inverted) {
    return !isLightInvertedBg;
  }

  return !isLightBg;
};

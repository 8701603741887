import { parseToHsl } from 'polished';
import create from 'zustand';
import { persist } from 'zustand/middleware';

import { ColorValues, defaultTheme, ICustomTheme, ITheme, prefersDarkMode, ThemeMode } from '../utils';
import { HslColor2, stringifyHsl } from '../utils/color-manipulation';

export const THEME_STORAGE_KEY = 'mosaic-theme';
export const DEFAULT_THEME_MODE = 'light';

export type ThemeState = {
  mode: ThemeMode;
  setMode: (mode: ThemeMode) => void;

  theme: ICustomTheme;
  setColor: (name: keyof ITheme['colors'], val: string | HslColor2) => void;
  reset: () => void;

  colorValues: Partial<ColorValues>;
  setColorValues: (cv: ColorValues) => void;

  invertedColorValues: Partial<ColorValues>;
  setInvertedColorValues: (cv: ColorValues) => void;
};

/**
 * For SSR
 */
const memoryDb: any = {};
const memoryStorage = {
  getItem: name => memoryDb[name],
  setItem: (name, value) => {
    memoryDb[name] = value;
  },
};

const defaultMode = () => {
  if (typeof localStorage === 'undefined') return DEFAULT_THEME_MODE;

  try {
    return JSON.parse(localStorage.getItem(THEME_STORAGE_KEY)).mode;
  } catch {}

  const dataTheme = document.documentElement.getAttribute('data-theme');
  if (dataTheme) return dataTheme as ThemeMode;

  return DEFAULT_THEME_MODE;
};

export const useThemeStore = create<ThemeState>(
  persist(
    set => ({
      mode: defaultMode(),

      theme: {
        colors: {},
      },

      // default is light theme
      colorValues: {
        light: true,
      },
      invertedColorValues: {
        light: false,
      },

      setMode: mode => {
        const preferDark = prefersDarkMode();

        if (typeof document !== 'undefined') {
          let _mode = mode;
          if (mode === 'system') {
            _mode = preferDark ? 'dark' : 'light';
          }

          document.documentElement.setAttribute('data-theme', _mode);
        }

        set(state => ({ ...state, mode }));
      },

      setColor: (name, val) =>
        set(state => {
          // if would result in no change, return
          if (defaultTheme.colors[name] === val || state.theme.colors[name] === val) return;

          // allow setting by hex or rgb val
          let hslObj: HslColor2;
          if (typeof val === 'string') {
            const x = parseToHsl(val);
            hslObj = { h: Math.round(x.hue), s: Math.round(x.saturation * 100), l: Math.round(x.lightness * 100) };
          } else {
            hslObj = val;
          }

          const hslString = stringifyHsl(hslObj);

          // if new val equals the default val, delete it so that it's removed from localStorage
          // this way if we update default theme in the future, the user will get the new values
          if (defaultTheme.colors[name] === hslString) {
            delete state.theme.colors[name];
            return;
          }

          return {
            theme: {
              ...state.theme,
              colors: {
                ...state.theme.colors,
                [name]: hslString,
              },
            },
          };
        }),

      reset: () => set({ theme: { colors: {} } }),
      setColorValues: cv => set({ colorValues: cv }),
      setInvertedColorValues: cv => set({ invertedColorValues: cv }),
    }),
    {
      name: THEME_STORAGE_KEY,
      version: 0,

      getStorage: () => (typeof localStorage === 'undefined' ? memoryStorage : localStorage),

      // only remember the desired mode
      serialize: ({ state, version }) => JSON.stringify({ mode: state.mode, version }),

      deserialize: value => ({ version: 0 /* default */, ...JSON.parse(value) }),
    },
  ),
);

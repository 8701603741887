import cn from 'clsx';
import * as React from 'react';

import { Box } from '../Box';
import { BoxProps } from '../Box/types';

export type AspectRatioOwnProps = {
  /**
   * The aspect ratio of the Box. Common values are:
   *
   * `21/9`, `16/9`, `9/16`, `4/3`, `1.85/1`
   */
  ratio?: number;
};

export type AspectRatioProps = AspectRatioOwnProps & BoxProps<'div'>;

export function AspectRatio({ ratio = 4 / 3, className, children, style = {}, ...props }: AspectRatioProps) {
  // enforce single child
  const child = React.Children.only(children);

  return (
    <Box
      {...props}
      pos="relative"
      className={cn('sl-aspect-ratio', className)}
      style={{
        ...style,
        // @ts-expect-error react css typings intentionally don't include index type, this is expected
        '--ratio': ratio,
      }}
    >
      {child}
    </Box>
  );
}

import cn from 'clsx';
import * as React from 'react';
import { useContext } from 'react';

export const InvertThemeContext = React.createContext<{ inverted?: boolean }>({});

export const InvertTheme = ({ children, inverted }: { children: JSX.Element; inverted?: boolean }) => {
  const { inverted: parentInverted } = useContext(InvertThemeContext);
  let _inverted = undefined;
  if (inverted !== false) {
    _inverted = parentInverted !== void 0 ? !parentInverted : true;
  }

  const _className = cn(children.props.className, {
    'sl-inverted': inverted === false ? undefined : true,
  });

  return (
    <InvertThemeContext.Provider value={{ inverted: _inverted }}>
      {React.cloneElement(children, { className: _className || undefined })}
    </InvertThemeContext.Provider>
  );
};

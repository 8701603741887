import cn from 'clsx';
import * as React from 'react';
import { memo } from 'react';

import { Box, BoxProps } from '../Box';

export type ProseProps = BoxProps<'div'>;

export const Prose = memo(function Prose({ className, ...props }: ProseProps) {
  return <Box className={cn('sl-prose', className)} {...props} />;
});

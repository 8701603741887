import cn from 'clsx';
import memoize from 'nano-memoize';
import equals from 'react-fast-compare';

import { computePseudoClasses, Pseudo } from './pseudo';
import { EnhancerFn } from './types';

export type BoxShadowVals = true | false | 'sm' | 'md' | 'lg' | 'xl' | 'xl' | '2xl';

export interface IShadowProps {
  boxShadow?: BoxShadowVals | Pseudo<BoxShadowVals, 'hover' | 'focus'>;
}

export const shadowPropNames: Array<keyof IShadowProps> = ['boxShadow'];

export const shadowProps: EnhancerFn<IShadowProps> = (props: IShadowProps) => {
  const { boxShadow, ...rest } = props;

  return {
    props: rest,
    className: _shadowProps(boxShadow),
  };
};

const _shadowProps = memoize(
  (boxShadow: IShadowProps['boxShadow']) => {
    return cn(computePseudoClasses('shadow', boxShadow));
  },
  { maxAge: Infinity, equals },
);

import cn from 'clsx';
import memoize from 'nano-memoize';
import equals from 'react-fast-compare';

import { buildClassname, computePseudoClasses, Pseudo } from './pseudo';
import { NegativeSpaceVals, SpaceVals } from './spacing';
import { EnhancerFn } from './types';

type PositionVals = 'static' | 'fixed' | 'absolute' | 'relative' | 'sticky';
type PositionLocVals = 'auto' | SpaceVals | NegativeSpaceVals;
type PinVals = true | PositionLocVals;
type ZIndexVals = -1 | 0 | 10 | 20 | 40 | 50 | 'auto';

export interface IPositionProps {
  pos?: PositionVals;
  pin?: PinVals;
  pinY?: PinVals;
  pinX?: PinVals;
  top?: PositionLocVals;
  left?: PositionLocVals;
  right?: PositionLocVals;
  bottom?: PositionLocVals;
  zIndex?: ZIndexVals | Pseudo<ZIndexVals, 'focus'>;
}

export const positionPropNames: Array<keyof IPositionProps> = [
  'bottom',
  'pin',
  'pinX',
  'pinY',
  'left',
  'pos',
  'right',
  'top',
  'zIndex',
];

export const positionProps: EnhancerFn<IPositionProps> = (props: IPositionProps) => {
  const { pos, pin, pinY, pinX, top, left, right, bottom, zIndex, ...rest } = props;

  return { props: rest, className: _positionProps(pos, pin, pinY, pinX, top, left, right, bottom, zIndex) };
};

const _positionProps = memoize(
  (
    pos: IPositionProps['pos'],
    pin: IPositionProps['pin'],
    pinY: IPositionProps['pinY'],
    pinX: IPositionProps['pinX'],
    top: IPositionProps['top'],
    left: IPositionProps['left'],
    right: IPositionProps['right'],
    bottom: IPositionProps['bottom'],
    zIndex: IPositionProps['zIndex'],
  ) => {
    return cn(
      {
        [`sl-${pos}`]: pos,
        [buildClassname('inset', pin === true ? '0' : pin)]: pin !== void 0,
        [buildClassname('inset-y', pinY === true ? '0' : pinY)]: pinY !== void 0,
        [buildClassname('inset-x', pinX === true ? '0' : pinX)]: pinX !== void 0,
        [buildClassname('top', top)]: top !== void 0,
        [buildClassname('left', left)]: left !== void 0,
        [buildClassname('right', right)]: right !== void 0,
        [buildClassname('bottom', bottom)]: bottom !== void 0,
      },
      computePseudoClasses('z', zIndex),
    );
  },
  { maxAge: Infinity, equals },
);

import cn from 'clsx';
import memoize from 'nano-memoize';
import equals from 'react-fast-compare';

import { computePseudoClasses, Pseudo } from './pseudo';
import { EnhancerFn } from './types';

export type FontSizeVals = '2xs' | 'xs' | 'sm' | 'base' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl';

export type LineHeights = 'none' | 'tight' | 'snug' | 'normal' | 'relaxed' | 'loose';
export type LetterSpacing = 'tight' | 'normal' | 'wide';

export type ParagraphSizes = 'paragraph-leading' | 'paragraph' | 'paragraph-small' | 'paragraph-tiny';

export type FontFamilyVals = 'ui' | 'prose' | 'mono';
export type FontWeightVals = 'normal' | 'light' | 'medium' | 'semibold' | 'bold';
export type FontStyleVals = 'italic' | 'non-italic';
export type TextAlign = 'left' | 'center' | 'right' | 'justify';
export type TextDecoration = 'underline' | 'line-through' | 'no-underline';
export type TextTransform = 'uppercase' | 'lowercase' | 'capitalize' | 'normal-case';
export type TextOverflow = 'truncate' | 'overflow-ellipsis' | 'overflow-clip';
export type VerticalAlign = 'baseline' | 'top' | 'middle' | 'bottom' | 'text-top' | 'text-bottom';
export type Whitespace = 'normal' | 'nowrap' | 'pre' | 'pre-line' | 'pre-wrap';
export type WordBreak = 'normal' | 'words' | 'all';

export interface ITypographyProps {
  fontSize?: FontSizeVals | ParagraphSizes;
  lineHeight?: LineHeights | ParagraphSizes;
  letterSpacing?: LetterSpacing;
  fontFamily?: FontFamilyVals;
  fontWeight?: FontWeightVals;
  fontStyle?: FontStyleVals;
  textAlign?: TextAlign;
  textDecoration?: TextDecoration | Pseudo<TextDecoration, 'hover'>;
  textTransform?: TextTransform;
  textOverflow?: TextOverflow;
  verticalAlign?: VerticalAlign;
  whitespace?: Whitespace;
  wordBreak?: WordBreak;
}

export const typographyPropNames: Array<keyof ITypographyProps> = [
  'fontFamily',
  'fontSize',
  'fontStyle',
  'fontWeight',
  'letterSpacing',
  'lineHeight',
  'textAlign',
  'textDecoration',
  'textOverflow',
  'textTransform',
  'verticalAlign',
  'whitespace',
  'wordBreak',
];

export const typographyProps: EnhancerFn<ITypographyProps> = (props: ITypographyProps) => {
  const {
    fontSize,
    lineHeight,
    letterSpacing,
    fontFamily,
    fontWeight,
    fontStyle,
    textAlign,
    textDecoration,
    textTransform,
    textOverflow,
    verticalAlign,
    whitespace,
    wordBreak,
    ...rest
  } = props;

  return {
    props: rest,
    className: _typographyProps(
      fontSize,
      lineHeight,
      letterSpacing,
      fontFamily,
      fontWeight,
      fontStyle,
      textAlign,
      textDecoration,
      textTransform,
      textOverflow,
      verticalAlign,
      whitespace,
      wordBreak,
    ),
  };
};

const _typographyProps = memoize(
  (
    fontSize: ITypographyProps['fontSize'],
    lineHeight: ITypographyProps['lineHeight'],
    letterSpacing: ITypographyProps['letterSpacing'],
    fontFamily: ITypographyProps['fontFamily'],
    fontWeight: ITypographyProps['fontWeight'],
    fontStyle: ITypographyProps['fontStyle'],
    textAlign: ITypographyProps['textAlign'],
    textDecoration: ITypographyProps['textDecoration'],
    textTransform: ITypographyProps['textTransform'],
    textOverflow: ITypographyProps['textOverflow'],
    verticalAlign: ITypographyProps['verticalAlign'],
    whitespace: ITypographyProps['whitespace'],
    wordBreak: ITypographyProps['wordBreak'],
  ) => {
    return cn(
      {
        [`sl-text-${fontSize}`]: fontSize,
        [`sl-leading-${lineHeight}`]: lineHeight,
        [`sl-tracking-${letterSpacing}`]: letterSpacing,
        [`sl-font-${fontFamily}`]: fontFamily,
        [`sl-font-${fontWeight}`]: fontWeight,
        [`sl-${fontStyle}`]: fontStyle,
        [`sl-text-${textAlign}`]: textAlign,
        [`sl-${textTransform}`]: textTransform,
        [`sl-${textOverflow}`]: textOverflow,
        [`sl-align-${verticalAlign}`]: verticalAlign,
        [`sl-whitespace-${whitespace}`]: whitespace,
        [`sl-break-${wordBreak}`]: wordBreak,
      },
      computePseudoClasses('', textDecoration),
    );
  },
  { maxAge: Infinity, equals },
);

import cn from 'clsx';
import memoize from 'nano-memoize';
import equals from 'react-fast-compare';

import { buildClassname, computePseudoClasses, Pseudo } from './pseudo';
import { EnhancerFn } from './types';

export type Cursor = true | 'auto' | 'pointer' | 'wait' | 'text' | 'move' | 'not-allowed' | 'zoom-in' | 'zoom-out';
export type UserSelect = 'none' | 'text' | 'all' | 'auto';
export type Resize = true | 'none' | 'y' | 'x';
export type PointerEvents = 'none' | 'auto';
export type OpacityVals = 0 | 5 | 10 | 20 | 30 | 40 | 50 | 60 | 70 | 80 | 90 | 100;
export type VisibilityVals = 'visible' | 'invisible';

export interface IInteractivityProps {
  cursor?: Cursor;
  opacity?: OpacityVals | Pseudo<OpacityVals, 'hover' | 'focus' | 'active' | 'disabled'>;
  pointerEvents?: PointerEvents;
  resize?: Resize;
  userSelect?: UserSelect;
  visibility?: VisibilityVals | Pseudo<VisibilityVals, 'groupHover' | 'groupFocus'>;
}

export const interactivityPropNames: Array<keyof IInteractivityProps> = [
  'cursor',
  'opacity',
  'pointerEvents',
  'resize',
  'userSelect',
  'visibility',
];

export const interactivityProps: EnhancerFn<IInteractivityProps> = (props: IInteractivityProps) => {
  const { cursor, userSelect, pointerEvents, opacity, resize, visibility, ...rest } = props;

  return {
    props: rest,
    className: _interactivityProps(cursor, userSelect, pointerEvents, opacity, resize, visibility),
  };
};

const _interactivityProps = memoize(
  (
    cursor: IInteractivityProps['cursor'],
    userSelect: IInteractivityProps['userSelect'],
    pointerEvents: IInteractivityProps['pointerEvents'],
    opacity: IInteractivityProps['opacity'],
    resize: IInteractivityProps['resize'],
    visibility: IInteractivityProps['visibility'],
  ) => {
    return cn(
      {
        [buildClassname('cursor', cursor)]: cursor,
        [`sl-select-${userSelect}`]: userSelect,
        [`sl-pointer-events-${pointerEvents}`]: pointerEvents,
        [buildClassname('resize', resize)]: resize,
      },
      // the visibility value itself is the class, no need to pass name
      computePseudoClasses('', visibility),
      computePseudoClasses('opacity', opacity),
    );
  },
  { maxAge: Infinity, equals },
);

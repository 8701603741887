import { useCallback, useState } from 'react';
import useResizeObserver from 'use-resize-observer';

export type Breakpoints = Array<[string, number]>;

/**
 * Updates whenever the component's width matches a provided breakpoint
 *
 * `useBreakpoints([['compact', 600], ['regular', Infinity]])`
 *
 * https://github.com/ZeeCoder/use-resize-observer#breakpoints
 */
export const useBreakpoints = (breakpoints?: Breakpoints) => {
  const [breakpoint, setBreakpoint] = useState<string | undefined>(undefined);

  const onResize = useCallback(
    ({ width }) => {
      if (!breakpoints) return;

      for (let i = 0; i < breakpoints.length; i++) {
        if (width <= breakpoints[i][1]) {
          setBreakpoint(breakpoints[i][0]);
          break;
        }
      }
    },
    [breakpoints],
  );

  const { ref } = useResizeObserver({
    onResize,
  });

  return { ref, breakpoint };
};

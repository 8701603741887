import { FocusableRefValue } from '@react-types/shared';
import * as React from 'react';
import { forwardRef, RefObject } from 'react';

import { useClipboard } from '../../hooks';
import { Button, ButtonOwnProps } from './Button';

export type CopyButtonProps = { copyValue: string } & ButtonOwnProps;

function CopyButton({ copyValue, ...props }: CopyButtonProps, ref: RefObject<FocusableRefValue<HTMLElement>>) {
  const { hasCopied, onCopy } = useClipboard(copyValue);

  return (
    <Button
      appearance="minimal"
      size="sm"
      onPress={onCopy}
      icon={hasCopied ? undefined : 'copy'}
      label="Copy"
      {...props}
      // @ts-expect-error
      ref={ref}
    >
      {hasCopied ? 'Copied' : null}
    </Button>
  );
}

const _CopyButton = forwardRef(CopyButton);
export { _CopyButton as CopyButton };

import cn from 'clsx';
import memoize from 'nano-memoize';
import equals from 'react-fast-compare';

import { EnhancerFn } from './types';

export type DisplayVals = 'block' | 'inline-block' | 'inline' | 'flex' | 'inline-flex' | 'hidden';
export type OverflowVals = 'auto' | 'scroll' | 'hidden' | 'visible';
export type ObjectFitVals = 'contain' | 'cover' | 'fill' | 'none' | 'scale-down';
export type ObjectPositionVals =
  | 'bottom'
  | 'center'
  | 'left'
  | 'left-bottom'
  | 'left-top'
  | 'right'
  | 'right-bottom'
  | 'right-top'
  | 'top';

export interface ILayoutProps {
  display?: DisplayVals;
  overflowY?: OverflowVals;
  overflowX?: OverflowVals;
  objectFit?: ObjectFitVals;
  objectPosition?: ObjectPositionVals;
}

export const layoutPropNames: Array<keyof ILayoutProps> = [
  'display',
  'overflowX',
  'overflowY',
  'objectFit',
  'objectPosition',
];

export const layoutProps: EnhancerFn<ILayoutProps> = props => {
  const { display, overflowX, overflowY, objectFit, objectPosition, ...rest } = props;

  return { props: rest, className: _layoutProps(display, overflowX, overflowY, objectFit, objectPosition) };
};

const _layoutProps = memoize(
  (
    display: ILayoutProps['display'],
    overflowX: ILayoutProps['overflowX'],
    overflowY: ILayoutProps['overflowY'],
    objectFit: ILayoutProps['objectFit'],
    objectPosition: ILayoutProps['objectPosition'],
  ) => {
    return cn({
      [`sl-${display}`]: display !== void 0,
      [`sl-overflow-x-${overflowX}`]: overflowX,
      [`sl-overflow-y-${overflowY}`]: overflowY,
      [`sl-object-${overflowY}`]: objectFit,
      [`sl-object-${objectPosition}`]: objectPosition,
    });
  },
  { maxAge: Infinity, equals },
);

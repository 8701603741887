import { AriaMenuOptions } from '@react-aria/menu';
import * as React from 'react';
import { useCallback, useMemo } from 'react';

import { Item, Section } from '../Collections';
import { NoSsr } from '../NoSsr';
import { isMenuDivider, isMenuGroup, isMenuOption, isMenuOptionGroup, isSubmenuItem } from './guards';
import { MenuContext, MenuContextProps } from './MenuContext';
import { MenuKeyboardDelegate } from './MenuKeyboardDelegate';
import { MenuNodes } from './MenuNodes';
import { GenericMenuItem, MenuItems, MenuOptionItem } from './types';
import { useMenuState } from './useMenuState';

export type MenuListProps = {
  /** The object describing the items to show in the menu. */
  items: MenuItems;

  /**
   * Called when the Menu should close.
   */
  onClose?: () => void;

  /**
   * By default the Menu will only close when a non checkable `MenuItem` is pressed. Setting `closeOnPress` to true will cause
   * the menu to close when any `MenuItem` or `MenuOption` is pressed. Can still be overriden at the menu item level.
   * @default false
   */
  closeOnPress?: boolean;

  /**
   * Adjusts the overall size of the menu and its contents.
   */
  size?: MenuContextProps['size'];

  /**
   * Adjusts the default cursor when user hovers over items in the menu.
   */
  cursor?: MenuContextProps['cursor'];
} & Pick<AriaMenuOptions<any>, 'aria-label'>;

export function MenuList({ items, onClose, closeOnPress, size, cursor, ...props }: MenuListProps) {
  const state = useMenuState({ items: items as GenericMenuItem[], children: MenuListChildren });

  const keyboardDelegate = useMemo(() => new MenuKeyboardDelegate(state), [state]);

  const handleOnClose = useCallback(() => {
    // make sure to collapse all submenus when menu close is triggered
    state.collapseAllKeys();

    if (onClose) onClose();
  }, [onClose, state]);

  return (
    <NoSsr>
      <MenuContext.Provider
        value={{
          state,
          keyboardDelegate,
          closeOnPress,
          onClose: handleOnClose,
          size,
          cursor,
        }}
      >
        <MenuNodes {...props} className="sl-menu" autoFocus items={state.collection} />
      </MenuContext.Provider>
    </NoSsr>
  );
}

function MenuOption(item: MenuOptionItem) {
  return <Item key={item.id || item.value || item.title} id={item.id || item.value} title={item.value || item.title} />;
}

let idCounter = 1;
function MenuListChildren(item: GenericMenuItem) {
  if (isMenuGroup(item)) {
    return (
      <Section
        key={item.id || item.title}
        id={item.id}
        title={item.title}
        // @ts-expect-error
        items={item.children || []}
        // eslint-disable-next-line react/no-children-prop
        children={MenuListChildren}
      />
    );
  }

  if (isMenuOptionGroup(item)) {
    return (
      <Section
        key={item.id || item.title}
        id={item.id}
        title={item.title}
        items={item.children || []}
        // eslint-disable-next-line react/no-children-prop
        children={MenuOption}
      />
    );
  }

  if (isMenuOption(item)) {
    return MenuOption(item);
  }

  if (isMenuDivider(item)) {
    return <Item key={idCounter++} />;
  }

  return (
    <Item
      key={item.id || item.title}
      id={item.id}
      title={item.title}
      childItems={item.children}
      hasChildItems={!!(item.children && item.children.length)}
      // eslint-disable-next-line react/no-children-prop
      children={MenuListChildren}
    />
  );
}

import { IconName } from '@fortawesome/fontawesome-svg-core';
import { useProgressBar } from '@react-aria/progress';
import cn from 'clsx';
import * as React from 'react';

import { BackgroundColorVals } from '../../enhancers';
import { getCssVariable } from '../../utils';
import { Box } from '../Box';
import { Button } from '../Button/Button';
import { Flex } from '../Flex';
import { Icon } from '../Icon';
import { VStack } from '../Stack';

export type ProgressBarProps = {
  label: string;
  isLabelVisuallyHidden?: boolean;
  valueLabel?: string | JSX.Element;
  value: number;
  minValue: number;
  maxValue: number;
  approachingProgressLimitValue?: number;
  hintText?: string;
  color?: BackgroundColorVals;
  approachingProgressLimitColor?: BackgroundColorVals;
  approachingProgressLimitIcon?: IconName;
  atOrOverProgressLimitColor?: BackgroundColorVals;
  atOrOverProgressLimitIcon?: IconName;
  hasCalloutBorder?: boolean;
  actionButtonContent?: string | JSX.Element;
  onActionButtonPress?: () => void;
  icon?: IconName;
};

type ProgressTheme = {
  color?: BackgroundColorVals;
  icon?: IconName;
};

const DEFAULT_PROGRESS_BAR_PROPS: ProgressBarProps = {
  label: 'progress bar',
  color: 'primary',
  value: 0,
  minValue: 0,
  maxValue: 100,
};

export const ProgressBar = ({
  label,
  isLabelVisuallyHidden = false,
  valueLabel,
  value,
  minValue,
  maxValue,
  approachingProgressLimitValue,
  hintText,
  color = 'primary',
  approachingProgressLimitColor,
  approachingProgressLimitIcon,
  atOrOverProgressLimitColor,
  atOrOverProgressLimitIcon,
  hasCalloutBorder = false,
  actionButtonContent,
  onActionButtonPress,
  icon,
}: ProgressBarProps): JSX.Element => {
  const [progressState, setProgressState] = React.useState<ProgressBarProps>(DEFAULT_PROGRESS_BAR_PROPS);
  const [progressTheme, setProgressTheme] = React.useState<ProgressTheme>({ icon: icon, color: color });
  const [progressAction, setProgressAction] = React.useState<string | JSX.Element>();

  const { progressBarProps, labelProps } = useProgressBar(progressState);

  // Calculate the width of the progress bar as a percentage
  const progressValueAsPercentage = (value - minValue) / (maxValue - minValue);
  const progressBarWidth = progressValueAsPercentage > 1 ? '100%' : `${Math.round(progressValueAsPercentage * 100)}%`;

  const sanitizedProgressColor = getCssVariable(`--color-${progressTheme.color}`);

  const getProgressTheme = React.useCallback(() => {
    const isApproachingProgressLimit =
      progressState.value >= approachingProgressLimitValue && progressState.value < progressState.maxValue;
    const isAtOrOverProgressLimit = progressState.value >= progressState.maxValue;

    if (isAtOrOverProgressLimit) {
      setProgressTheme({
        color: atOrOverProgressLimitColor || progressTheme.color,
        icon: atOrOverProgressLimitIcon || progressTheme.icon,
      });
    } else if (isApproachingProgressLimit) {
      setProgressTheme({
        color: approachingProgressLimitColor || progressTheme.color,
        icon: approachingProgressLimitIcon || progressTheme.icon,
      });
    } else {
      setProgressTheme({
        color: color,
        icon: icon,
      });
    }
  }, [
    approachingProgressLimitColor,
    approachingProgressLimitIcon,
    approachingProgressLimitValue,
    atOrOverProgressLimitColor,
    atOrOverProgressLimitIcon,
    color,
    icon,
    progressState.maxValue,
    progressState.value,
    progressTheme.color,
    progressTheme.icon,
  ]);

  React.useEffect(() => {
    if (value !== null) {
      setProgressState({ label, isLabelVisuallyHidden, valueLabel, value, minValue, maxValue });
      setProgressAction(actionButtonContent);
      getProgressTheme();
    }
  }, [label, value, minValue, maxValue, getProgressTheme, actionButtonContent, valueLabel, isLabelVisuallyHidden]);

  return (
    <Box
      className={cn('sl-callout')}
      w="full"
      rounded="xl"
      pb={hasCalloutBorder && 1}
      bg={hasCalloutBorder ? progressTheme.color : undefined}
      role="alert"
      style={{ borderColor: hasCalloutBorder && sanitizedProgressColor }}
      {...progressBarProps}
    >
      <Box
        className={hasCalloutBorder && 'sl-border-2'}
        style={{ borderColor: hasCalloutBorder && sanitizedProgressColor }}
        px={hasCalloutBorder && 6}
        py={hasCalloutBorder && 6}
        alignItems="start"
        rounded="xl"
        bg={hasCalloutBorder ? 'canvas-pure' : undefined}
      >
        <Flex justifyContent="between" mb={6}>
          <Flex>
            <Flex flexDirection="row" alignItems="center">
              {progressTheme.icon && (
                <Box mr={4}>
                  <Icon size="lg" color={sanitizedProgressColor} icon={['fal', progressTheme.icon]} />
                </Box>
              )}
              <Box>
                {progressState.label && !progressState.isLabelVisuallyHidden && (
                  <Box fontSize="paragraph" {...labelProps} lineHeight="none">
                    {label}
                  </Box>
                )}
                {progressState.valueLabel && progressState.valueLabel}
              </Box>
            </Flex>
          </Flex>
          {progressAction &&
            (typeof progressAction === 'string' ? (
              <Button style={{ width: '128px', minWidth: '128px', marginLeft: '8px' }} onPress={onActionButtonPress}>
                {progressAction}
              </Button>
            ) : (
              progressAction
            ))}
        </Flex>
        {hintText && (
          <Box mb={6} fontSize="paragraph-small">
            {hintText}
          </Box>
        )}
        <Box className="sl-progress-bar" bg="canvas-200" style={{ height: 6 }}>
          <Box
            bg={progressTheme.color}
            style={{
              width: progressBarWidth,
              height: 6,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

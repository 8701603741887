import { PressResponder } from '@react-aria/interactions';
import { useMenuTrigger } from '@react-aria/menu';
import { useMenuTriggerState } from '@react-stately/menu';
import * as React from 'react';
import { ReactElement, useCallback, useEffect, useMemo, useRef } from 'react';

import { MaybeRenderProp, Placement, runIfFn } from '../../utils';
import { Popover } from '../Popover';
import { MenuList, MenuListProps } from './MenuList';

export type MenuProps = {
  /**
   * The element that will trigger the Tooltip. Must be pressable. Can use a function for more control.
   */
  renderTrigger: MaybeRenderProp<{ isOpen: boolean }, ReactElement>;

  /**
   * The placement of the Menu overlay with respect to its trigger element.
   * @default bottom
   */
  placement?: Placement;

  /**
   * Pass true to render the tooltip without an arrow.
   * @default false
   */
  hideArrow?: boolean;

  /**
   * Whether the menu width should equal the width of the element that is triggering the menu.
   */
  matchTriggerWidth?: boolean;
} & MenuListProps;

const openMenus = {};
let menuId = 0;

export function Menu({
  renderTrigger,
  placement = 'bottom',
  hideArrow,
  onClose,
  closeOnPress = false,
  'aria-label': ariaLabel,
  matchTriggerWidth,
  ...props
}: MenuProps) {
  const id = useMemo(() => `${++menuId}`, []);

  useEffect(() => {
    // make sure to cleanup on unmount in case the menu is unmounted via a method other than official close handler
    return () => {
      if (openMenus[id]) delete openMenus[id];
    };
  }, [id]);

  // only one menu allowed open at a time
  const closeOpenMenus = useCallback(() => {
    for (let hideMenuId in openMenus) {
      if (hideMenuId !== id) {
        openMenus[hideMenuId](true);
        delete openMenus[hideMenuId];
      }
    }
  }, [id]);

  const onOpenChange = (isOpen: boolean) => {
    if (isOpen) {
      closeOpenMenus();
      trackOpenMenu();
    } else {
      if (onClose) onClose();
    }
  };

  // Create state based on the incoming props
  const state = useMenuTriggerState({
    ...props,
    onOpenChange,
    closeOnSelect: closeOnPress,
  });

  function trackOpenMenu() {
    openMenus[id] = state.close;
  }

  // Get props for the menu trigger and menu elements
  const ref = useRef();
  const { menuTriggerProps, menuProps } = useMenuTrigger({ type: 'menu' }, state, ref);

  const triggerElem = runIfFn(renderTrigger, { isOpen: state.isOpen });

  return (
    <>
      <PressResponder aria-label={ariaLabel} {...menuTriggerProps} ref={ref} isPressed={state.isOpen}>
        {triggerElem}
      </PressResponder>
      {state.isOpen ? (
        <Popover
          isOpen
          placement={placement}
          triggerRef={ref}
          appearance="minimal"
          onClose={state.close}
          showArrow={!hideArrow}
          autoFocus
          restoreFocus
          contain
          type="menu"
          isNonModal
          matchTriggerWidth={matchTriggerWidth}
        >
          <MenuList
            {...props}
            {...menuProps}
            aria-labelleddby={ariaLabel}
            closeOnPress={closeOnPress}
            onClose={state.close}
          />
        </Popover>
      ) : null}
    </>
  );
}

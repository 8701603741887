import {
  borderPropNames,
  BorderProps,
  colorPropNames,
  flexPropNames,
  IColorProps,
  IFlexProps,
  IInteractivityProps,
  ILayoutProps,
  interactivityPropNames,
  IPositionProps,
  IShadowProps,
  ISizeProps,
  ISpacingProps,
  ITypographyProps,
  layoutPropNames,
  positionPropNames,
  shadowPropNames,
  sizePropNames,
  spacingPropNames,
  transformPropNames,
  TransformProps,
  typographyPropNames,
} from '../enhancers';

const propNames = [
  'as',
  ...borderPropNames,
  ...colorPropNames,
  ...flexPropNames,
  ...interactivityPropNames,
  ...layoutPropNames,
  ...positionPropNames,
  ...shadowPropNames,
  ...sizePropNames,
  ...spacingPropNames,
  ...typographyPropNames,
  ...transformPropNames,
];

interface IEnhancerProps
  extends BorderProps,
    IColorProps,
    IFlexProps,
    IInteractivityProps,
    ILayoutProps,
    IPositionProps,
    IShadowProps,
    ISizeProps,
    ISpacingProps,
    ITypographyProps,
    TransformProps {}

interface SplitBoxProps<P extends IEnhancerProps> {
  matchedProps: Pick<P, keyof IEnhancerProps>;
  remainingProps: Pick<P, Exclude<keyof P, keyof IEnhancerProps>>;
}

/**
 * Convenience method to split the Box props.
 *
 * Useful for when you want to pass all of the Box props to the root Box and
 * pass the remaining props to a child element (e.g: disabled, readOnly, required, etc).
 */
export function splitBoxProps<P extends IEnhancerProps>(props: P): SplitBoxProps<P> {
  return splitProps(props, propNames as Array<keyof IEnhancerProps>);
}

type Omit<T extends Dictionary<any>, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

interface Dictionary<T> {
  [key: string]: T;
}

export interface SplitProps<P, K extends keyof P> {
  matchedProps: Pick<P, K>;
  remainingProps: Omit<P, K>;
}

/**
 * Utility to split props based on an array of keys
 */
export function splitProps<P extends Dictionary<any>, K extends keyof P>(props: P, keys: K[]): SplitProps<P, K> {
  const matchedProps = {} as Pick<P, K>;
  const remainingProps = {} as P;
  const propKeys = Object.keys(props) as K[];

  for (let i = 0; i < propKeys.length; i++) {
    const propKey = propKeys[i];
    const propValue = props[propKey];

    if (keys.includes(propKey)) {
      matchedProps[propKey] = propValue;
    } else {
      remainingProps[propKey] = propValue;
    }
  }

  return { matchedProps, remainingProps };
}

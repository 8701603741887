import { focusSafely, FocusScope } from '@react-aria/focus';
import { PressResponder } from '@react-aria/interactions';
import { OverlayTriggerProps, useModal, useOverlayTrigger } from '@react-aria/overlays';
import { mergeProps } from '@react-aria/utils';
import { useDOMRef, useUnwrapDOMRef } from '@react-spectrum/utils';
import { useOverlayTriggerState } from '@react-stately/overlays';
import { DOMRef, DOMRefValue } from '@react-types/shared';
import * as React from 'react';
import {
  CSSProperties,
  forwardRef,
  LegacyRef,
  MutableRefObject,
  ReactElement,
  RefObject,
  useCallback,
  useEffect,
  useRef,
} from 'react';

import { IPositionProps } from '../../enhancers';
import { useOverlay, useOverlayPosition, useThemeIsDark } from '../../hooks';
import { MaybeRenderProp, Placement, runIfFn } from '../../utils';
import { Box, BoxOwnProps } from '../Box';
import { Icon, IIconProps } from '../Icon';
import { DismissButton, Overlay } from '../Overlay';

type PopoverAppearanceVals = 'default' | 'minimal';

/**
 * Expose a select set of props to customize the popover wrapper
 */
type PopoverBoxProps = Pick<BoxOwnProps, 'p'>;

export type PopoverProps = {
  /**
   * The element that will trigger the Popover. Should be a button in most cases. Can use a function for more control.
   */
  renderTrigger?: MaybeRenderProp<{ isOpen: boolean }, ReactElement>;

  /**
   * The content to render in the Popover. Can use a function to access Popover state and for more control over close.
   */
  children: MaybeRenderProp<{ close: () => void }>;

  /**
   * The ref of the element the Popover should visually attach itself to.
   */
  triggerRef?: RefObject<HTMLElement>;

  scrollRef?: RefObject<HTMLElement>;

  /**
   * Whether the Popover is open (controlled mode).
   */
  isOpen?: boolean;

  /**
   * Whether to default the Popover to open (uncontrolled mode).
   */
  defaultOpen?: boolean;

  /**
   * Called when the Popover opens.
   */
  onOpen?: () => void;

  /**
   * Called when the Popover closes.
   */
  onClose?: () => void;

  /**
   * The placement of the Popover overlay with respect to its trigger element.
   */
  placement?: Placement;

  /**
   * Whether the element should flip its orientation (e.g. top to bottom or left to right) when there is insufficient
   * room for it to render completely. Defaults to true.
   */
  shouldFlip?: boolean;

  /**
   * The additional offset applied along the main axis between the element and its anchor element.
   */
  offset?: number;

  /**
   * The additional offset applied along the cross axis between the element and its anchor element.
   */
  crossOffset?: number;

  /**
   * Whether to contain focus inside the popover, so that users cannot move focus outside.
   */
  contain?: boolean;

  /**
   * Whether to auto focus the first focusable element in the popover on mount.
   */
  autoFocus?: boolean;

  /**
   * Whether to restore focus back to the element that was focused
   * when the popover mounted, after the popover unmounts.
   */
  restoreFocus?: boolean;

  appearance?: PopoverAppearanceVals;

  /**
   * Pass true to render the tooltip with an arrow.
   */
  showArrow?: boolean;

  /** The type of popover. */
  type?: OverlayTriggerProps['type'];

  /* Should this popover be presented as a modal? */
  isNonModal?: boolean;

  /**
   * Whether the menu width should equal the width of the element that is triggering the menu.
   */
  matchTriggerWidth?: boolean;

  boundaryElement?: HTMLElement;

  zIndex?: IPositionProps['zIndex'];
} & PopoverBoxProps;

export const Popover = forwardRef((props: PopoverProps, ref: MutableRefObject<HTMLDivElement>) => {
  const {
    renderTrigger,
    children,
    isOpen,
    defaultOpen,
    placement = 'bottom',
    scrollRef,
    onOpen,
    onClose,
    contain = true,
    autoFocus = true,
    restoreFocus = true,
    offset = props.showArrow ? 10 : 8,
    crossOffset = 0,
    shouldFlip = true,
    p,
    appearance = 'default',
    showArrow,
    type = 'dialog',
    isNonModal,
    matchTriggerWidth,
    boundaryElement,
  } = props;

  const onOpenChange = useCallback(
    $isOpen => {
      if ($isOpen && onOpen) onOpen();
      if (!$isOpen && onClose) onClose();
    },
    [onClose, onOpen],
  );

  let state = useOverlayTriggerState({
    isOpen,
    defaultOpen,
    onOpenChange,
  });

  const triggerRef = useRef<HTMLElement>();
  const consumerProvidedRef = !!props.triggerRef;

  const popoverRef = useRef<DOMRefValue<HTMLDivElement>>();
  let unwrappedPopoverRef = useUnwrapDOMRef(popoverRef);

  // Get props for the trigger and overlay. This also handles
  // hiding the overlay when a parent element of the trigger scrolls
  // (which invalidates the popover positioning).
  let { triggerProps, overlayProps } = useOverlayTrigger({ type }, state, triggerRef);

  // Get popover positioning props relative to the trigger
  let {
    overlayProps: positionProps,
    arrowProps,
    arrowIcon,
  } = useOverlayPosition({
    targetRef: props.triggerRef || triggerRef,
    overlayRef: unwrappedPopoverRef,
    placement,
    offset,
    scrollRef,
    crossOffset,
    boundaryElement,
    shouldFlip,
    isOpen: state.isOpen,
    onClose,
    matchTriggerWidth,
  });

  const triggerPropsWithRef = {
    ...triggerProps,
    ref: consumerProvidedRef ? undefined : triggerRef,
  };

  // if isOpen is provided (controlled mode), do not use our own onPress handler
  const onPress = isOpen === void 0 ? state.toggle : undefined;

  const triggerElem = renderTrigger ? runIfFn(renderTrigger, { isOpen: state.isOpen }) : null;

  return (
    <>
      {triggerElem && (
        <PressResponder {...triggerPropsWithRef} onPress={onPress} isPressed={state.isOpen}>
          {triggerElem}
        </PressResponder>
      )}

      <Overlay isOpen={state.isOpen}>
        <PopoverWrapper
          {...overlayProps}
          style={mergeProps(positionProps.style, { zIndex: props.zIndex })}
          ref={popoverRef}
          isOpen={state.isOpen}
          onClose={state.close}
          p={p}
          contain={contain}
          autoFocus={autoFocus}
          restoreFocus={restoreFocus}
          appearance={appearance}
          isNonModal={isNonModal}
          type={type}
        >
          {runIfFn(children, { close: state.close })}
          {showArrow && <TooltipArrow icon={arrowIcon} style={arrowProps.style} />}
        </PopoverWrapper>
      </Overlay>
    </>
  );
});

type PopoverWrapperProps = Pick<
  PopoverProps,
  'children' | 'isOpen' | 'onClose' | 'contain' | 'autoFocus' | 'restoreFocus' | 'appearance' | 'isNonModal' | 'type'
> &
  PopoverBoxProps & {
    style?: CSSProperties;
  };

const popoverVariants: Record<PopoverAppearanceVals, BoxOwnProps> = {
  default: {
    p: 4,
    bg: 'canvas-dialog',
  },
  minimal: {},
};

const PopoverWrapper = forwardRef(
  (
    {
      children,
      isOpen,
      onClose,
      contain,
      autoFocus,
      restoreFocus,
      appearance,
      isNonModal,
      type,
      ...otherProps
    }: PopoverWrapperProps,
    ref: DOMRef<HTMLDivElement>,
  ) => {
    let domRef = useDOMRef(ref);

    // Handle interacting outside the dialog and pressing
    // the Escape key to close the modal.
    let { overlayProps } = useOverlay(
      {
        onClose,
        isOpen,
        isDismissable: true,
        shouldCloseOnBlur: type === 'listbox',
      },
      domRef,
    );

    // Hide content outside the modal from screen readers.
    let { modalProps } = useModal({ isDisabled: isNonModal });

    const { color, ...containerProps } = mergeProps(overlayProps, otherProps, modalProps);
    const variantProps = popoverVariants[appearance] || {};

    // Focus the popover itself on mount, unless a child element is already focused.
    useEffect(() => {
      // without the requestAnimationFrame, interactions with popover in cypress testing environment fail
      requestAnimationFrame(() => {
        if (domRef.current && !domRef.current.contains(document.activeElement)) {
          focusSafely(domRef.current);
        }
      });
    }, [domRef]);

    return (
      <FocusScope contain={contain} restoreFocus={restoreFocus} autoFocus={autoFocus}>
        <DismissButton onDismiss={onClose} />
        <Box
          {...containerProps}
          {...variantProps}
          className="sl-popover"
          ref={domRef as LegacyRef<HTMLDivElement>}
          role="presentation"
          tabIndex={-1}
          display="inline-flex"
          data-testid="popover"
          data-ispopover="true"
        >
          {children}
        </Box>
        <DismissButton onDismiss={onClose} />
      </FocusScope>
    );
  },
);

function TooltipArrow({ icon, style }: { icon: IIconProps['icon']; style?: CSSProperties }) {
  const isDark = useThemeIsDark();

  return (
    <Box className="sl-popover__tip" pos="absolute" color={isDark ? 'canvas-dialog' : 'canvas-pure'} style={style}>
      <Icon icon={icon} />
    </Box>
  );
}

import * as React from 'react';

import { PartialSpectrumCollectionNode } from '../../utils';
import { SelectOptionProps } from './types';

// See examples of getCollectionNode https://github.com/adobe/react-spectrum/search?q=getCollectionNode
export const SelectOption = (_props: SelectOptionProps): React.ReactElement => {
  return null;
};

export const isSelectOption = (item: unknown): item is SelectOptionProps => {
  return item && typeof item === 'object' && item.hasOwnProperty('value') ? true : false;
};

SelectOption.getCollectionNode = function* getCollectionNode(
  props: SelectOptionProps,
): Generator<PartialSpectrumCollectionNode<object>> {
  const { value, label } = props;

  const textValue = (typeof label === 'string' ? label : '') || props['aria-label'] || value || '';

  yield {
    type: 'item',
    key: String(value) || label || 'empty',
    props,
    rendered: label || value,
    textValue,
    'aria-label': props['aria-label'],
    hasChildNodes: false,
  };
};

import * as React from 'react';
import shallow from 'zustand/shallow';

import { DEFAULT_THEME_MODE, THEME_STORAGE_KEY, ThemeState, useThemeStore } from './hooks';
// @ts-expect-error
import styles from './main.css';
import { appendCss, replaceCss } from './utils';
import { computeTheme, getResolvedThemeMode, ICustomTheme, ThemeMode } from './utils/theme';

export const GLOBAL_CSS_ID = 'mosaic-global';
export const GLOBAL_CSS_THEME_ID = 'mosaic-theme';

export const injectStyles = ({ mode }: { mode?: ThemeMode } = {}) => {
  // do not attempt if we are not in a browser environment
  if (typeof document === 'undefined') return;

  appendCss(GLOBAL_CSS_ID, styles);

  return subscribeTheme({ mode });
};

export const subscribeTheme = ({ mode: initialMode }: { mode?: ThemeMode } = {}) => {
  // do not attempt if we are not in a browser environment
  if (typeof document === 'undefined') return;

  // allow user to force a starting theme mode if they so desire
  if (initialMode) {
    useThemeStore.getState().setMode(initialMode);
  }

  const { theme, mode } = useThemeStore.getState();

  /**
   * If user did not init theme earlier, do so now
   */
  const dataTheme = document.documentElement.getAttribute('data-theme');
  if (!dataTheme) {
    document.documentElement.setAttribute('data-theme', getResolvedThemeMode(mode));
  }

  injectTheme(theme, mode);

  const unsub = useThemeStore.subscribe(
    (state: ThemeState) => {
      try {
        injectTheme(state.theme, state.mode);
      } catch (e) {
        // noop, usually due to in flight theme change while user is editing
        console.warn('Error computing and applying theme', e);
      }
    },
    s => ({ mode: s.mode, theme: s.theme }),
    shallow,
  );

  return unsub;
};

const injectTheme = (theme: ICustomTheme, mode: ThemeMode) => {
  const { setColorValues, setInvertedColorValues } = useThemeStore.getState();

  if (typeof document !== 'undefined') {
    const userMode = getResolvedThemeMode(mode);
    document.documentElement.setAttribute('data-theme', userMode);
  }

  const { css: lightCss } = computeTheme(theme, 'light');
  const { css: darkCss } = computeTheme(theme, 'dark');
  replaceCss(`${GLOBAL_CSS_THEME_ID}-light`, lightCss);
  replaceCss(`${GLOBAL_CSS_THEME_ID}-dark`, darkCss);

  const { colorValues, invertedColorValues } = computeTheme(theme, mode);
  setColorValues(colorValues);
  setInvertedColorValues(invertedColorValues);
};

export const InlineStyles = () => {
  return (
    <>
      <style id={GLOBAL_CSS_ID} type="text/css" dangerouslySetInnerHTML={{ __html: styles }} />
      <InlineTheme />
    </>
  );
};

const InlineTheme = () => {
  const { theme } = useThemeStore();

  return (
    <>
      <style
        id={`${GLOBAL_CSS_THEME_ID}-light`}
        type="text/css"
        dangerouslySetInnerHTML={{ __html: computeTheme(theme, 'light').css }}
      />
      <style
        id={`${GLOBAL_CSS_THEME_ID}-dark`}
        type="text/css"
        dangerouslySetInnerHTML={{ __html: computeTheme(theme, 'dark').css }}
      />
    </>
  );
};

/**
 * Small snippet to set the basics re theme as early as possible during rendering, to avoid major flashes of white etc.
 */
export const INIT_THEME_JS = `try {
  var query = window.matchMedia("(prefers-color-scheme: dark)");

  var preference = window.localStorage.getItem('${THEME_STORAGE_KEY}');
  preference = preference ? JSON.parse(preference) : { mode: "${DEFAULT_THEME_MODE}" };

  var theme = (preference.mode === "system" && query.matches) || preference.mode === "dark" ? "dark" : "light";
  document.documentElement.setAttribute('data-theme', theme);
} catch (e) {
  console.warn('problem setting initial theme mode', e);
}`;
export const InitTheme = () => {
  return <script dangerouslySetInnerHTML={{ __html: INIT_THEME_JS }} />;
};

import { PartialNode } from '@react-stately/collections';
import { ItemElement, ItemProps as _ItemProps } from '@react-types/shared';
import * as React from 'react';
import { Key, ReactElement, ReactNode } from 'react';

import { generateKey } from './keys';

export interface ItemProps<T> {
  id?: Key;
  /** Rendered contents of the item or child items. */
  children?: ReactNode;
  /** Rendered contents of the item if `children` contains child items. */
  title?: ReactNode; // label?? contents?
  /** A string representation of the item's contents, used for features like typeahead. */
  textValue?: string;
  /** An accessibility label for this item. */
  'aria-label'?: string;
  /** A list of child item objects. Used for dynamic collections. */
  childItems?: Iterable<T>;
  /** Whether this item has children, even if not loaded yet. */
  hasChildItems?: boolean;
}

/**
 * Pulled from https://github.com/adobe/react-spectrum/blob/main/packages/%40react-stately/collections/src/Item.ts
 * with some minor adjustments.
 */

export function Item<T>(props: ItemProps<T>): ReactElement {
  return null;
}

// See examples of getCollectionNode https://github.com/adobe/react-spectrum/search?q=getCollectionNode
Item.getCollectionNode = function* getCollectionNode<T>(
  props: ItemProps<T> & { id?: string; value?: string; type?: string },
  context: any,
): Generator<PartialNode<T>> {
  let { childItems, title, children } = props;

  let rendered = props.title || props.children;
  let textValue = props.textValue || (typeof rendered === 'string' ? rendered : '') || props['aria-label'] || '';

  yield {
    key: props.id,
    type: 'item',
    props,
    rendered,
    textValue,
    'aria-label': props['aria-label'],
    hasChildNodes: hasChildItems(props),
    *childNodes() {
      if (childItems) {
        for (let child of childItems) {
          if (child && typeof child === 'object') {
            yield {
              value: {
                // generate a key for groups/sections nested inside of items (for menus mostly)
                // @ts-expect-error
                id: typeof child.id !== 'undefined' ? child.id : `${generateKey(child.children)}-subitem`,
                ...child,
              },
            };
          } else {
            yield {
              value: child,
            };
          }
        }
      } else if (title) {
        let items: PartialNode<T>[] = [];
        React.Children.forEach(children, child => {
          items.push({
            // type: 'item', // do not constrain to just item children - sections etc are ok
            element: child as ItemElement<T>,
          });
        });

        yield* items;
      }
    },
  };
};

function hasChildItems<T>(props: ItemProps<T>) {
  if (props.hasChildItems !== null) {
    return props.hasChildItems;
  }

  if (props.childItems) {
    return true;
  }

  if (props.title && React.Children.count(props.children) > 0) {
    return true;
  }

  return false;
}

import cn from 'clsx';

type DefaultPseudoProps =
  | 'sm'
  | 'md'
  | 'first'
  | 'last'
  | 'odd'
  | 'even'
  | 'hover'
  | 'focus'
  | 'focusWithin'
  | 'active'
  | 'visited'
  | 'groupHover'
  | 'groupFocus'
  | 'disabled';

export type Pseudo<T, P extends DefaultPseudoProps> = {
  [key in P | 'default']?: T;
};

const isPseudoObject = (obj: any): obj is Pseudo<boolean | string | number, DefaultPseudoProps> =>
  obj && typeof obj === 'object';

export const isNegative = (v: boolean | string | number) => {
  return v < 0 || v === '-px';
};

export const buildClassname = (p: string, v: boolean | string | number) => {
  if (v === true) return `sl-${p}`;

  const isNeg = isNegative(v);
  return `sl-${isNeg ? '-' : ''}${p}${!p || isNeg ? '' : '-'}${v}`;
};

export const computePseudoClasses = (
  prop: string,
  val: boolean | string | number | Pseudo<unknown, DefaultPseudoProps>,
) => {
  let classes = {};

  // if val is literal true value, then we're trying to use default and do not need to append a value
  // val can be 0, account for this
  if (isPseudoObject(val)) {
    classes = {
      [buildClassname(prop, val.default)]: val.default || val.default === 0,
      [`sm:${buildClassname(prop, val.sm)}`]: val.sm || val.sm === 0,
      [`md:${buildClassname(prop, val.md)}`]: val.md || val.md === 0,
      [`first:${buildClassname(prop, val.first)}`]: val.first || val.first === 0,
      [`last:${buildClassname(prop, val.last)}`]: val.last || val.last === 0,
      [`odd:${buildClassname(prop, val.odd)}`]: val.odd || val.odd === 0,
      [`even:${buildClassname(prop, val.even)}`]: val.even || val.even === 0,
      [`hover:${buildClassname(prop, val.hover)}`]: val.hover || val.hover === 0,
      [`focus:${buildClassname(prop, val.focus)}`]: val.focus || val.focus === 0,
      [`focus-within:${buildClassname(prop, val.focusWithin)}`]: val.focusWithin || val.focusWithin === 0,
      [`active:${buildClassname(prop, val.active)}`]: val.active || val.active === 0,
      [`visited:${buildClassname(prop, val.visited)}`]: val.visited || val.visited === 0,
      [`group-hover:${buildClassname(prop, val.groupHover)}`]: val.groupHover || val.groupHover === 0,
      [`group-focus:${buildClassname(prop, val.groupFocus)}`]: val.groupFocus || val.groupFocus === 0,
      [`disabled:${buildClassname(prop, val.disabled)}`]: val.disabled || val.disabled === 0,
    };
  } else {
    classes[`sl${prop ? `-${prop}` : ''}${val === true ? '' : '-' + val}`] = val || val === 0;
  }

  return cn(classes);
};

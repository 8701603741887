import { BoxOwnProps } from '../Box/types';

export type AppearanceVals = 'minimal' | 'line' | 'pill' | 'solid';
export type OrientationVals = 'horizontal' | 'vertical';
export type DensityVals = 'compact' | 'regular';
export type AlignVals = 'center' | 'end' | 'start';
export type DirectionVals = 'ltr' | 'rtl';
export type VerticalStateAlignment = 'left' | 'right';

type DefaultOrientationRecord = VerticalOrientationRecord | HorizontalOrientationRecord;

type VerticalOrientationRecord = Record<
  'vertical',
  Record<DensityVals, Record<'tabList' | 'tab', Partial<BoxOwnProps>>>
>;

type VerticalOrientationAlignmentRecord = Record<
  'vertical',
  Record<VerticalStateAlignment, Record<DensityVals, Record<'tabList' | 'tab', Partial<BoxOwnProps>>>>
>;

type HorizontalOrientationRecord = Record<
  'horizontal',
  Record<DensityVals, Record<'tabList' | 'tab', Partial<BoxOwnProps>>>
>;

type DefaultAppearanceRecord = Record<'minimal' | 'pill' | 'solid', DefaultOrientationRecord>;

type LineAppearanceRecord = Record<'line', HorizontalOrientationRecord | VerticalOrientationAlignmentRecord>;

export const variants: DefaultAppearanceRecord | LineAppearanceRecord = {
  minimal: {
    horizontal: {
      regular: {
        tabList: {},
        tab: {
          py: 2,
          px: 3,
        },
      },
      compact: {
        tabList: {},
        tab: {
          py: 1.5,
          px: 2,
        },
      },
    },
    vertical: {
      regular: {
        tabList: {},
        tab: {
          py: 1.5,
          px: 3,
        },
      },
      compact: {
        tabList: {},
        tab: {
          py: 1.5,
          px: 3,
        },
      },
    },
  },
  line: {
    horizontal: {
      regular: {
        tabList: {},
        tab: {
          borderB: 2,
          py: 2,
          px: 4,
        },
      },
      compact: {
        tabList: {},
        tab: {
          borderB: 2,
          py: 1.5,
          px: 2,
        },
      },
    },
    vertical: {
      left: {
        regular: {
          tabList: {
            borderR: true,
          },
          tab: {
            borderL: 2,
            py: 2,
            px: 5,
          },
        },
        compact: {
          tabList: {
            borderR: true,
          },
          tab: {
            borderL: 2,
            py: 1.5,
            px: 3,
          },
        },
      },
      right: {
        regular: {
          tabList: {},
          tab: {
            borderR: 2,
            py: 2,
            px: 5,
          },
        },
        compact: {
          tabList: {},
          tab: {
            borderR: 2,
            py: 1.5,
            px: 3,
          },
        },
      },
    },
  },
  pill: {
    horizontal: {
      regular: {
        tabList: {},
        tab: {
          py: 2,
          px: 3,
          rounded: 'lg',
        },
      },
      compact: {
        tabList: {},
        tab: {
          py: 1,
          px: 2,
          rounded: 'lg',
        },
      },
    },
    vertical: {
      regular: {
        tabList: {},
        tab: {
          py: 1.5,
          px: 3,
          rounded: 'lg',
        },
      },
      compact: {
        tabList: {},
        tab: {
          py: 1,
          px: 2,
          rounded: 'lg',
        },
      },
    },
  },
  solid: {
    horizontal: {
      regular: {
        tabList: {},
        tab: {
          py: 2,
          px: 3,
        },
      },
      compact: {
        tabList: {},
        tab: {
          py: 1,
          px: 2,
        },
      },
    },
    vertical: {
      regular: {
        tabList: {
          borderR: true,
        },
        tab: {
          py: 2,
          px: 4,
        },
      },
      compact: {
        tabList: {
          borderR: true,
        },
        tab: {
          py: 1,
          px: 2,
        },
      },
    },
  },
};

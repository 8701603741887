import { FocusRing } from '@react-aria/focus';
import { useHover } from '@react-aria/interactions';
import { useTab } from '@react-aria/tabs';
import { mergeProps } from '@react-aria/utils';
import { SingleSelectListState } from '@react-stately/list';
import { DOMProps, Node } from '@react-types/shared';
import * as React from 'react';
import { useContext, useRef } from 'react';

import {
  BackgroundColorVals,
  BorderColorVals,
  IBackgroundColorProps,
  IntentVals,
  ITextColorProps,
  SpaceVals,
  TextColorVals,
} from '../../enhancers';
import { useIsInViewport } from '../../hooks/use-is-in-viewport';
import useScrollTo from '../../hooks/use-scroll-to';
import { Box, BoxOwnProps } from '../Box';
import { Item } from '../Collections';
import { TabsContext } from './TabsContext';
import { DensityVals, variants } from './variants';

export type TabOwnProps = {
  children: React.ReactNode;

  /**
   * An optional unique identifier (within this <Tabs /> component) for the tab - must match up with the id of a corresponding <TabPanel />.
   */
  id?: string;

  isDisabled?: boolean;

  intent?: IntentVals;
};

export const Tab = (props: TabOwnProps) => <Item {...props} />;
Tab.getCollectionNode = Item.getCollectionNode;

interface TabProps<T> extends DOMProps {
  item: Node<T>;
  state: SingleSelectListState<T>;
  isDisabled?: boolean;
  density?: DensityVals;
}

const IntentColorMap: Record<
  IntentVals,
  {
    color: TextColorVals;
    selectedColor: TextColorVals;
    bgTint: BackgroundColorVals;
    selectedBg: BackgroundColorVals;
    selectedBorder: BorderColorVals;
  }
> = {
  default: {
    color: 'light',
    selectedColor: 'primary-dark',
    bgTint: 'primary-tint',
    selectedBg: 'primary-light',
    selectedBorder: 'primary',
  },
  success: {
    color: 'success',
    selectedColor: 'success-dark',
    bgTint: 'success-tint',
    selectedBg: 'success',
    selectedBorder: 'success',
  },
  warning: {
    color: 'warning',
    selectedColor: 'warning-dark',
    bgTint: 'warning-tint',
    selectedBg: 'warning',
    selectedBorder: 'warning',
  },
  danger: {
    color: 'danger',
    selectedColor: 'danger-dark',
    bgTint: 'danger-tint',
    selectedBg: 'danger',
    selectedBorder: 'danger',
  },
};

export function TabImpl<T>(props: TabProps<T>) {
  const { item, state, density, isDisabled: propsDisabled } = props;
  const { key, rendered, index } = item;

  const intent: IntentVals = item.props?.intent || 'default';
  const isDisabled = propsDisabled || state.disabledKeys.has(key);

  const tabContext = useContext(TabsContext);
  const { tabsProps, refs: parentRefs } = tabContext;
  const { appearance, orientation, activeStateAlignment = 'right' } = tabsProps;

  const isPill = appearance === 'pill';
  const isSolid = appearance === 'solid';
  const isLine = appearance === 'line';
  const isMinimal = appearance === 'minimal';

  const disabledKeys = state.disabledKeys;

  // sync `isDisabled` prop with tab list state disabled keys
  React.useEffect(() => {
    if (propsDisabled && !disabledKeys.has(key)) {
      disabledKeys.add(key);
    } else if (!propsDisabled && disabledKeys.has(key)) {
      disabledKeys.delete(key);
    }
  }, [disabledKeys, propsDisabled, key]);

  const ref = useRef<HTMLDivElement>();
  const [scrollToTab] = useScrollTo<HTMLDivElement>(ref);
  const { isInViewport } = useIsInViewport<HTMLDivElement>(parentRefs.tablistRef);

  const { tabProps } = useTab({ key, isDisabled }, state, ref);

  const { hoverProps } = useHover({
    ...props,
  });
  const isSelected = state.selectedKey === key;

  const { color: _color, ...propsWithoutColor } = mergeProps(tabProps, hoverProps);

  const getStateProps: () => Partial<BoxOwnProps> = () => {
    if (appearance === 'line' && orientation === 'vertical') {
      return { ...variants[appearance][orientation][activeStateAlignment][density].tab };
    }
    return { ...variants[appearance][orientation][density].tab };
  };

  const stateProps = getStateProps();

  if (isMinimal) {
    if (orientation === 'vertical' && index === 0) {
      // @ts-expect-error
      stateProps.mt = (-1 * stateProps.py + 0.5) as SpaceVals;
    } else {
      if (index === 0) {
        // @ts-expect-error
        stateProps.ml = (-1 * stateProps.px + 0.5) as SpaceVals;
      }
    }
  }

  /**
   * If in loading or disabled states, remove other ui effects like hover
   */
  if (isDisabled) {
    for (const i in stateProps) {
      const prop = stateProps[i];
      if (prop && typeof prop === 'object') {
        // remove props immutably
        const { active, hover, ...newProps } = stateProps[i];
        stateProps[i] = newProps;
      }
    }
  }

  let bg: IBackgroundColorProps['bg'];
  let color: ITextColorProps['color'] = {
    default: isPill || isSolid ? IntentColorMap[intent].color : 'light',
    hover: isDisabled ? undefined : 'body',
  };

  if (isSelected) {
    if (isPill || isSolid) {
      color = 'body';
    } else if (isMinimal && intent === 'default') {
      color = 'body'; // override primary color for minimal tabs with the default intent
    } else {
      color = IntentColorMap[intent].selectedColor;
    }
  }

  if (isPill || isSolid) {
    bg = isSelected
      ? IntentColorMap[intent].selectedBg
      : {
          hover: !isDisabled ? IntentColorMap[intent].bgTint : undefined,
        };
  }

  React.useLayoutEffect(() => {
    if (isSelected && isInViewport) {
      scrollToTab();
    }
  }, [isInViewport, isSelected, scrollToTab]);

  return (
    <Box
      p={1}
      {...stateProps}
      borderColor={isSelected && isLine ? IntentColorMap[intent].selectedBorder : 'light'}
      bg={bg}
      color={color}
      cursor={isDisabled ? 'not-allowed' : isSelected ? true : 'pointer'}
      ref={ref}
      fontWeight="medium"
      opacity={isDisabled ? 60 : undefined}
      {...propsWithoutColor}
    >
      {rendered}
    </Box>
  );
}

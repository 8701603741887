import { useButton } from '@react-aria/button';
import { FocusRing } from '@react-aria/focus';
import { useHover } from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';
import { useFocusableRef } from '@react-spectrum/utils';
import { FocusableProps, FocusableRefValue, PressEvents } from '@react-types/shared';
import cn from 'clsx';
import * as React from 'react';
import { forwardRef, RefObject } from 'react';

import { BorderRoundedProps, BorderWidthProps, IntentVals } from '../../enhancers';
import { splitBoxProps } from '../../utils';
import { Box } from '../Box';
import { BoxOwnProps, PolymorphicComponentProps } from '../Box/types';
import { Icon, IIconProps, isIconProp } from '../Icon';
import { AppearanceVals, sizes, variants } from './variants';

export type ButtonOwnProps = PressEvents &
  FocusableProps &
  Omit<BorderWidthProps, 'border'> &
  Omit<BorderRoundedProps, 'rounded'> & {
    children?: React.ReactNode;

    appearance?: AppearanceVals;
    intent?: IntentVals;
    size?: 'sm' | 'md';
    active?: boolean;
    disabled?: boolean;
    loading?: boolean;
    icon?: IIconProps['icon'] | React.ReactElement;
    iconRight?: IIconProps['icon'] | React.ReactElement;
    autoFocus?: boolean;

    noFocusRing?: boolean;
    fullWidth?: boolean;

    // for accessibility, if want to override aria label
    // should be provided for buttons without text children and without a string icon
    label?: string;
  };

export type ButtonProps<E extends React.ElementType = typeof defaultElement> = PolymorphicComponentProps<
  E,
  ButtonOwnProps
>;

const defaultElement = 'button';

function Button<E extends React.ElementType>(
  {
    appearance = 'default',
    intent = 'default',
    size = 'md',
    disabled,
    loading,
    className,
    icon,
    iconRight,
    label,
    children,
    active,
    autoFocus,
    onPress,
    onPressChange,
    onPressEnd,
    onPressStart,
    onPressUp,
    noFocusRing,
    fullWidth,
    ...props
  }: ButtonProps<E>,
  ref: RefObject<FocusableRefValue<HTMLElement>>,
) {
  const domRef = useFocusableRef(ref);

  const { buttonProps } = useButton(
    { isDisabled: disabled, onPress, onPressChange, onPressEnd, onPressStart, onPressUp, ...props },
    domRef,
  );
  const { hoverProps } = useHover({ isDisabled: disabled, ...props });
  const { matchedProps, remainingProps } = splitBoxProps(props);

  const stateProps: Partial<BoxOwnProps> = {
    ...variants.default.default,
    ...variants.default[intent],
    ...variants[appearance]?.default,
    ...variants[appearance]?.[intent],
  };

  /**
   * If active, remove other state effects
   */
  if (active) {
    for (const i in stateProps) {
      const prop = stateProps[i];
      if (prop && typeof prop === 'object') {
        if (prop.hasOwnProperty('active')) {
          stateProps[i] = prop.active;
        } else if (prop.hasOwnProperty('hover')) {
          // remove props immutably
          const { hover, ...newProps } = stateProps[i];
          stateProps[i] = newProps;
        }
      }
    }
  }

  /**
   * If in loading or disabled states, remove other ui effects like hover
   */
  if (loading || disabled) {
    for (const i in stateProps) {
      const prop = stateProps[i];
      if (prop && typeof prop === 'object') {
        // remove props immutably
        const { active, hover, ...newProps } = stateProps[i];
        stateProps[i] = newProps;
      }
    }
  }

  const { color, ...propsWithoutColor } = mergeProps(matchedProps, buttonProps, hoverProps);

  const elem = (
    <Box
      as={defaultElement}
      px={sizes[size]?.px}
      fontSize={sizes[size]?.fontSize}
      fontWeight="medium"
      rounded={sizes[size]?.rounded}
      h={size}
      borderColor="button"
      className={cn('sl-button', className)}
      disabled={loading || disabled}
      cursor={loading ? 'wait' : disabled ? 'not-allowed' : undefined}
      border
      opacity={loading ? 70 : undefined}
      w={fullWidth ? 'full' : undefined}
      justifyContent={fullWidth ? 'center' : undefined}
      {...remainingProps}
      {...stateProps}
      {...propsWithoutColor}
      ref={domRef}
    >
      {loading ? <ButtonIcon icon="spinner" pulse size={size} hasContent={!!children} /> : null}
      {icon && !loading ? <ButtonIcon icon={icon} size={size} hasContent={!!children} /> : null}
      {children}
      {iconRight ? <ButtonRightIcon icon={iconRight} size={size} /> : null}
    </Box>
  );

  if (noFocusRing) {
    return elem;
  }

  return (
    <FocusRing focusRingClass="sl-focus-ring" autoFocus={autoFocus}>
      {elem}
    </FocusRing>
  );
}

const _Button = forwardRef(Button) as <T extends React.ElementType = 'button'>(
  props: ButtonProps<T> & { ref?: React.MutableRefObject<FocusableRefValue<HTMLElement>> },
) => React.ReactElement;
export { _Button as Button };

const ButtonIcon = ({
  icon,
  size,
  hasContent,
  pulse,
}: Pick<ButtonOwnProps, 'icon' | 'size'> & {
  pulse?: boolean;
  hasContent: Boolean;
}) => {
  let elem = icon;
  if (isIconProp(icon)) {
    elem = <Icon icon={icon} size={size === 'sm' ? 'sm' : undefined} pulse={pulse} fixedWidth />;
  }

  return (
    <Box mr={hasContent ? sizes[size]?.leftIconMr : undefined} mx={hasContent ? undefined : sizes[size]?.leftIconMx}>
      {elem}
    </Box>
  );
};

const ButtonRightIcon = ({ icon, size }: Pick<ButtonOwnProps, 'icon' | 'size'>) => {
  let elem = icon;
  if (isIconProp(icon)) {
    elem = <Icon icon={icon} fixedWidth />;
  }

  return (
    <Box ml={sizes[size]?.rightIconMl} mr={sizes[size]?.rightIconMr} fontSize={sizes[size]?.iconSize}>
      {elem}
    </Box>
  );
};

import { useTabList } from '@react-aria/tabs';
import { useTabListState } from '@react-stately/tabs';
import { CollectionChildren } from '@react-types/shared';
import * as React from 'react';
import { useContext, useEffect } from 'react';

import { FontSizeVals } from '../../enhancers';
import { BoxOwnProps } from '../Box';
import { Stack } from '../Stack';
import { TabImpl } from './Tab';
import { TabsContext } from './TabsContext';
import { DensityVals, variants } from './variants';

export type TabListOwnProps = {
  children: CollectionChildren<any>;

  /**
   * Font size of the tabs - defaults to inheriting whatever the font size of parent container is.
   */
  fontSize?: FontSizeVals;

  /**
   * Controls the amount of space and padding for the tabs. Defaults to `regular`.
   */
  density?: DensityVals;
};

/**
 * A TabList is used within Tabs to group tabs that a user can switch between.
 * The keys of the items within the <TabList> must match up with a corresponding item inside the <TabPanels>.
 */
export function TabList({ fontSize = 'lg', density = 'regular', ...props }: TabListOwnProps) {
  const tabContext = useContext(TabsContext);
  const { refs, tabState, tabsProps } = tabContext;
  const { orientation = 'horizontal', selectedId, onChange, appearance, activeStateAlignment = 'right' } = tabsProps;
  const { collapse, setTabListState } = tabState;
  const { tablistRef } = refs;

  const isPill = appearance === 'pill';

  // Pass original Tab props but override children to create the collection.
  const state = useTabListState({
    ...tabsProps,
    selectedKey: onChange ? selectedId : undefined,
    defaultSelectedKey: onChange ? undefined : selectedId,
    onSelectionChange: onChange,
    children: props.children,
  });

  const { tabListProps } = useTabList({ ...tabsProps, ...props }, state, tablistRef);

  useEffect(() => {
    // Passing back to root as useTabPanel needs the TabListState
    setTabListState(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.disabledKeys, state.selectedItem, state.selectedKey, props.children]);

  const { color, ...tabListPropsWithoutColor } = tabListProps;

  const getStateProps: () => Partial<BoxOwnProps> = () => {
    if (appearance === 'line' && orientation === 'vertical') {
      return { ...variants[appearance][orientation][activeStateAlignment][density].tabList };
    }
    return { ...variants[appearance][orientation][density].tabList };
  };

  const tabContent = (
    <Stack
      {...tabListPropsWithoutColor}
      ref={tablistRef}
      direction={orientation}
      {...getStateProps()}
      fontSize={fontSize}
      spacing={isPill ? 2 : undefined}
      h={orientation === 'vertical' ? 'full' : 'auto'}
      overflowY={orientation === 'vertical' ? 'auto' : 'hidden'}
      overflowX={'hidden'}
    >
      {[...state.collection].map(item => {
        return (
          <TabImpl key={item.key} item={item} state={state} density={density} isDisabled={item.props?.isDisabled} />
        );
      })}
    </Stack>
  );

  return tabContent;

  // TODO: auto collapse w Select when overflows
  // if (orientation === 'vertical') {
  //   return tabContent;
  // } else {
  //   return (
  //     <div ref={wrapperRef} className={classNames(styles, 'collapseWrapper')}>
  //       {collapse ? (
  //         <Select
  //           {...props}
  //           {...tabProps}
  //           id={tabPanelProps['aria-labelledby']}
  //           state={state}
  //           className={tabListclassName}
  //         />
  //       ) : (
  //         tabContent
  //       )}
  //     </div>
  //   );
  // }
}

import {
  getContrast,
  getLuminance,
  mix,
  parseToHsl,
  parseToRgb,
  readableColor as _readableColor,
  rgba,
} from 'polished';

export type HslColor2 = { h: number; s: number; l: number; a?: number };

export const getContrastColor = (
  bgLuminance: number,
  fgLuminance: number,
  targetContrast: number,
  hue: number,
  saturation: number = 1,
  lightness: number = 50,
) => {
  let targetLuminance: number;
  if (fgLuminance < bgLuminance) {
    targetLuminance = (bgLuminance + 0.05) / targetContrast - 0.05;
  } else {
    targetLuminance = targetContrast * (bgLuminance + 0.05) - 0.05;
  }

  return setLuminance(targetLuminance, stringifyHsl({ h: hue, s: saturation, l: lightness }));
};

export const parseHsl = (hsl: string): HslColor2 => {
  const hslRegexp = /hsl\((\d+),\s*([\d.]+)%,\s*([\d.]+)%\)/g;
  const hslaRegexp = /hsla\((\d+),\s*([\d.]+)%,\s*([\d.]+)%,\s*([\d.]+)\)/g;
  let parts = hslRegexp.exec(hsl);
  if (!parts) {
    parts = hslaRegexp.exec(hsl);
  }

  if (!parts) {
    try {
      const hslParts = parseToHsl(hsl);
      return {
        h: Math.round(hslParts.hue),
        s: parseFloat((hslParts.saturation * 100).toFixed(0)),
        l: Math.max(1, Math.min(99, Math.round(hslParts.lightness * 100))),
        // @ts-expect-error
        a: hslParts.alpha !== void 0 ? hslParts.alpha : 1,
      };
    } catch (e) {
      console.warn(e);
      return { h: 0, s: 0, l: 100, a: 1 };
    }
  }

  return { h: parseInt(parts[1]), s: parseFloat(parts[2]), l: parseFloat(parts[3]) };
};

export const stringifyHsl = (hsl: HslColor2): string => {
  if (hsl.a !== void 0) {
    return `hsla(${hsl.h}, ${hsl.s}%, ${hsl.l}%, ${hsl.a})`;
  } else {
    return `hsl(${hsl.h}, ${hsl.s}%, ${hsl.l}%)`;
  }
};

const EPS = 1e-7;
const setLuminance = (amount, color): string => {
  // @ts-expect-error
  const { alpha = 1 } = parseToRgb(color);

  let rgb;
  if (amount === 0) {
    rgb = rgba(0, 0, 0, alpha);
  } else if (amount === 1) {
    rgb = rgba(255, 255, 255, alpha);
  } else {
    let maxIteration = 20;
    const test = (color1, color2) => {
      const mixed = mix(0.5, color1, color2);
      const mixedLuminance = getLuminance(mixed);
      if (Math.abs(amount - mixedLuminance) < EPS || !maxIteration--) {
        return mixed;
      }

      if (mixedLuminance > amount) {
        return test(color1, mixed);
      }

      return test(mixed, color2);
    };

    rgb = getLuminance(color) > amount ? test('#000', color) : test(color, '#fff');
  }

  return rgb;
};

export const randomColorFromString = (
  str: string,
  $opts?: { h?: [number, number]; s?: [number, number]; l?: [number, number] },
) => {
  try {
    var h, s, l;
    const opts = $opts || {};

    // allow any hue
    opts.h = opts.h || [0, 360];

    // limit saturation - we generally like saturation to keep things "pop-y", but too much saturation and white text gets difficult to read
    opts.s = opts.s || [60, 80];

    // ditto above re carefully selecting the range here to balance variability with readability of white text
    opts.l = opts.l || [30, 65];

    var range = function (hash, min, max) {
      var diff = max - min;
      var x = ((hash % diff) + diff) % diff;
      return x + min;
    };

    var hash = 0;
    if (str.length === 0) return '#FFF';

    for (var i = 0; i < str.length; i++) {
      hash = (str.charCodeAt(i) % 96) * 14 + ((hash << 5) - hash);
      hash = hash & hash;
    }

    h = range(hash, opts.h[0], opts.h[1]);
    s = range(hash, opts.s[0], opts.s[1]);

    /**
     * HSL's lightness doesn't tend to correspond well to how light or dark your eyes see colors
     * The simple solution is to darken yellows and cyans and lighten your blues a little bit
     */
    let minL = opts.l[0];
    let maxL = opts.l[1];
    const isCyan = h > 155 && h < 205;
    const isYellow = h > 35 && h < 85;
    const isBlue = h > 215 && h < 265;
    if (isCyan || isYellow) maxL -= 15;
    if (isBlue) minL += 10;

    l = range(hash, minL, maxL);

    return `hsl(${h}, ${s}%, ${l}%)`;
  } catch (e) {
    console.warn(`randomColorFromString.error - could not parse color string ${str}`);
    return '#FFF';
  }
};

/**
 * Returns white or black, based on which provides higher contrast when rendered on top of the passed in `color`.
 * @param color - the color in hex, rgb, or hsl
 * @param leeway - integer from -10 to 10. The more positive the number, the more that white will be weighted. Defaults to 0.
 */
export const readableColor = (color: string, leeway: number = 0) => {
  const white = '#fff';
  const black = '#000';

  try {
    const whiteContrast = getContrast(color, white);
    const blackContrast = getContrast(color, black);

    // Prefer white as a contrast color if it is within {leeway} units of black
    const readable = blackContrast - whiteContrast <= leeway ? white : black;

    return readable;
  } catch (e) {
    console.warn(`readableColor.error - could not parse color string ${color}`);
    return white;
  }
};

import * as React from 'react';

import { Item } from '../Collections';

export type TabPanelOwnProps = {
  children: React.ReactNode;

  /**
   * An optional unique identifier (within this <Tabs /> component) for the TabPanel - must match up with the id of a corresponding <Tab />.
   */
  id?: string;
};

export const TabPanel = (props: TabPanelOwnProps) => <Item {...props} />;
TabPanel.getCollectionNode = Item.getCollectionNode;

import cn from 'clsx';
import * as React from 'react';
import { forwardRef } from 'react';

import {
  borderProps,
  colorProps,
  EnhancerFn,
  flexProps,
  interactivityProps,
  layoutProps,
  marginProps,
  paddingProps,
  positionProps,
  ringProps,
  shadowProps,
  sizeProps,
  transformProps,
  typographyProps,
} from '../../enhancers';
import { BoxOwnProps, BoxProps } from './types';

const defaultElement = 'div';

export const Box: <E extends React.ElementType = typeof defaultElement>(props: BoxProps<E>) => JSX.Element = forwardRef(
  function Box({ as, className, role, noFocusRing, children, ...props }: BoxOwnProps, ref: React.Ref<Element>) {
    const classNames: string[] = [];

    // custom pipe to just make accumulating the generated classNames, and pulling out props, easier
    const pipe =
      (...fns: EnhancerFn<BoxOwnProps>[]) =>
      x =>
        fns.reduce((v, f) => {
          const { props, className } = f(v);
          classNames.push(className);
          return props;
        }, x);

    // run the props through all the enhancers - what we're left with is the extra props that we're not specifically handling
    // these get passed on to the underlying Element as is
    const restProps = pipe(
      layoutProps,
      flexProps,
      positionProps,
      sizeProps,
      typographyProps,
      marginProps,
      paddingProps,
      colorProps,
      borderProps,
      ringProps,
      shadowProps,
      interactivityProps,
      transformProps,
    )(props);

    const _className = cn(className, classNames, { 'sl-group': role === 'group', 'sl-no-focus-ring': noFocusRing });

    const Element = as || defaultElement;

    return (
      <Element ref={ref} {...restProps} className={_className || undefined} role={role}>
        {children}
      </Element>
    );
  },
);

// @ts-ignore
Box.displayName = 'Box';

// @ts-ignore
Box.defaultProps = {
  as: defaultElement,
};

// Adapted from https://github.com/adobe/react-spectrum/blob/main/packages/%40react-stately/tree/src/useTreeState.ts

import { useCollection } from '@react-stately/collections';
import { SelectionManager } from '@react-stately/selection';
import { Collection, CollectionBase } from '@react-types/shared';
import { Key, useCallback, useEffect, useMemo, useRef } from 'react';
import create from 'zustand';
import shallow from 'zustand/shallow';

import { MenuCollection, MenuNode } from './MenuCollection';
import { MultipleSelectionState } from './selection';
import { useMultipleSelectionState } from './selection/useMultipleSelectionState';
import { GenericMenuItem } from './types';

export interface MenuProps extends CollectionBase<GenericMenuItem> {}
export interface MenuState extends Pick<MultipleSelectionState, 'useIsFocusedKey' | 'useFocusedKey'> {
  /** A collection of items in the tree. */
  readonly collection: Collection<MenuNode>;

  /** A set of keys for items that are expanded. */
  readonly expandedKeys: Set<Key>;

  /** Not used, but required for the typings */
  readonly disabledKeys: Set<Key>;

  /** Expands the given key, and closes any other expanded keys at the same menu level (only one can be expanded at a time) */
  toggleKey(key: Key): void;

  /** Collapses the given key */
  collapseKey(key: Key): void;

  /** Collapse all keys */
  collapseAllKeys(): void;

  /** A selection manager to read and update multiple selection state. */
  readonly selectionManager: SelectionManager;

  /** Whether the given is corrently focused */
  readonly useIsExpandedKey: (key: Key) => boolean;
}

const disabledKeys = new Set<Key>();

type ObservableMenuState = Pick<MenuState, 'expandedKeys' | 'toggleKey' | 'collapseKey' | 'collapseAllKeys'>;

/**
 * Provides state management for tree-like components. Handles building a collection
 * of items from props, item expanded state, and manages multiple selection state.
 */
export function useMenuState(props: MenuProps): MenuState {
  // stable expandedKeys set for use in keyboard delegate, etc
  const expandedKeysRef = useRef(new Set<Key>());

  const selectionState = useMultipleSelectionState(props);
  const tree = useCollection(props, (nodes: Iterable<MenuNode>) => new MenuCollection(nodes), null, []);
  const selectionManager = useMemo(() => new SelectionManager(tree, selectionState), [selectionState, tree]);

  const useStore = useRef(
    create<ObservableMenuState>(set => ({
      // mirrored expanded keys set for use in observers in components etc
      expandedKeys: new Set<Key>(),
      toggleKey: k => {
        const newSet = new Set<Key>(toggleKey(tree, expandedKeysRef.current, k));
        return set(state => ({
          ...state,
          expandedKeys: newSet,
        }));
      },
      collapseKey: k => {
        const newSet = new Set<Key>(collapseKey(expandedKeysRef.current, k));
        return set(state => ({
          ...state,
          expandedKeys: newSet,
        }));
      },
      collapseAllKeys: () => {
        expandedKeysRef.current = new Set<Key>();
        return set(state => ({
          ...state,
          expandedKeys: expandedKeysRef.current,
        }));
      },
    })),
  );

  const [onToggle, onCollapse, onCollapseAllKeys] = useStore.current(
    state => [state.toggleKey, state.collapseKey, state.collapseAllKeys],
    shallow,
  );

  function useIsExpandedKey(key: Key) {
    const cb = useCallback(state => state.expandedKeys.has(key), [key]);
    if (!useStore.current) return false;
    return useStore.current(cb);
  }

  // Reset focused key if that item is deleted from the collection.
  useEffect(() => {
    if (selectionState.focusedKey != null && !tree.getItem(selectionState.focusedKey)) {
      selectionState.setFocusedKey(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tree, selectionState.focusedKey]);

  return {
    useIsFocusedKey: selectionState.useIsFocusedKey,
    useFocusedKey: selectionState.useFocusedKey,
    useIsExpandedKey,
    collection: tree,
    expandedKeys: expandedKeysRef.current,
    disabledKeys,
    toggleKey: onToggle,
    collapseKey: onCollapse,
    collapseAllKeys: onCollapseAllKeys,
    selectionManager,
  };
}

function toggleKey(tree: MenuCollection, set: Set<Key>, key: Key) {
  const parents = [];

  const item = tree.getItem(key);
  const hasChildNodes = item?.hasChildNodes;

  let parent = item?.parentKey ? tree.getItem(item.parentKey) : null;
  while (parent) {
    parents.unshift(parent.key);
    if (parent.parentKey) {
      parent = tree.getItem(parent.parentKey);
    } else {
      parent = null;
    }
  }

  for (const expandedKey of set) {
    if (!parents.includes(expandedKey)) {
      set.delete(expandedKey);
    }
  }

  // if item has child nodes and is not yet expanded, expand!
  if (hasChildNodes && !set.has(key)) {
    set.add(key);
  }

  return set;
}

function collapseKey(set: Set<Key>, key: Key) {
  if (set.has(key)) {
    set.delete(key);
  }

  return set;
}

import * as React from 'react';

export type MosaicContextProps = {
  componentOverrides: {
    Link?: React.ComponentType<React.AnchorHTMLAttributes<HTMLAnchorElement>>;
    Image?: React.ComponentType<React.ImgHTMLAttributes<HTMLImageElement>>;
  };
};

export const defaultMosaicContext: MosaicContextProps = { componentOverrides: {} };

type InternalMosaicContextProps = {
  providerId?: string;
  providerRef?: React.RefObject<HTMLDivElement>;
} & MosaicContextProps;

export const MosaicContext = React.createContext<InternalMosaicContextProps>(defaultMosaicContext);

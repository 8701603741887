import cn from 'clsx';
import * as React from 'react';
import { forwardRef, memo } from 'react';

import { useMosaicContext } from '../../hooks/use-mosaic-context';
import { Box, PolymorphicComponentProps } from '../Box';

export type LinkOwnProps = {
  href?: string;
};

export type LinkProps<E extends React.ElementType = typeof defaultElement> = PolymorphicComponentProps<E, LinkOwnProps>;

const defaultElement = 'a';

export const Link: <E extends React.ElementType = typeof defaultElement>(props: LinkProps<E>) => JSX.Element = memo(
  forwardRef(<E extends React.ElementType>({ className, ...restProps }: LinkProps<E>, ref: typeof restProps.ref) => {
    const {
      componentOverrides: { Link: LinkOverride },
    } = useMosaicContext();

    return <Box as={LinkOverride || defaultElement} ref={ref} className={cn('sl-link', className)} {...restProps} />;
  }),
);

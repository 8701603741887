import { CollectionChildren } from '@react-types/shared';
import * as React from 'react';
import { ReactElement } from 'react';

import { PartialSpectrumCollectionNode } from '../../utils';
import { SelectSectionProps } from './types';

type SelectSectionPropsWithChildren = SelectSectionProps & { children: CollectionChildren<object> };

// See examples of getCollectionNode https://github.com/adobe/react-spectrum/search?q=getCollectionNode
export function SelectSection(props: SelectSectionPropsWithChildren): ReactElement {
  return null;
}

export const isSelectSection = (item: unknown): item is SelectSectionProps => {
  return item && typeof item === 'object' && item.hasOwnProperty('options') ? true : false;
};

SelectSection.getCollectionNode = function* getCollectionNode(
  props: SelectSectionPropsWithChildren,
): Generator<PartialSpectrumCollectionNode<object>> {
  const { children, options } = props;

  yield {
    key: props.id || props.title,
    type: 'section',
    hasChildNodes: true,
    rendered: props.title,
    props,
    'aria-label': props['aria-label'],
    *childNodes() {
      if (typeof children === 'function') {
        if (!options) {
          throw new Error('SelectSection props.options is missing');
        }

        for (let item of options) {
          yield {
            type: 'item',
            value: item,
            renderer: children,
          };
        }
      } else {
        let items: PartialSpectrumCollectionNode<object>[] = [];
        React.Children.forEach(children, child => {
          items.push({
            type: 'item',
            element: child,
          });
        });

        yield* items;
      }
    },
  };
};

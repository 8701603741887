import cn from 'clsx';
import memoize from 'nano-memoize';
import equals from 'react-fast-compare';

import { EnhancerFn } from './types';

export type FlexVals = 1 | 'auto' | 'initial' | 'none';
export type FlexDirectionVals = 'row' | 'row-reverse' | 'col' | 'col-reverse';
export type FlexWrapVals = true | 'reverse' | 'no-wrap';
export type FlexGrowVals = true | 0;
export type FlexShrinkVals = true | 0;
export type JustifyContentVals = 'start' | 'end' | 'center' | 'between' | 'around' | 'evenly';
export type JustifyItemsVals = 'auto' | 'start' | 'end' | 'center' | 'stretch';
export type JustifySelfVals = 'auto' | 'start' | 'end' | 'center' | 'stretch';
export type AlignContentVals = 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly';
export type AlignItemsVals = 'start' | 'end' | 'center' | 'baseline' | 'stretch';
export type AlignSelfVals = 'auto' | 'flex-start' | 'flex-end' | 'center' | 'stretch';

export interface IFlexProps {
  flex?: FlexVals;
  flexDirection?: FlexDirectionVals;
  flexWrap?: FlexWrapVals;
  flexGrow?: FlexGrowVals;
  flexShrink?: FlexShrinkVals;
  justifyContent?: JustifyContentVals;
  justifyItems?: JustifyItemsVals;
  justifySelf?: JustifySelfVals;
  alignContent?: AlignContentVals;
  alignItems?: AlignItemsVals;
  alignSelf?: AlignSelfVals;
}

export interface IFlexShorthandProps {
  align?: IFlexProps['alignItems'];
  justify?: IFlexProps['justifyContent'];
  wrap?: IFlexProps['flexWrap'];
  direction?: IFlexProps['flexDirection'];
  grow?: IFlexProps['flexGrow'];
  shrink?: IFlexProps['flexShrink'];
}

export const flexPropNames: Array<keyof IFlexProps> = [
  'flex',
  'flexDirection',
  'flexWrap',
  'flexGrow',
  'flexShrink',
  'justifyContent',
  'justifyItems',
  'justifySelf',
  'alignContent',
  'alignItems',
  'alignSelf',
];

export const flexProps: EnhancerFn<IFlexProps> = (props: IFlexProps) => {
  const {
    flex,
    flexDirection,
    flexWrap,
    flexGrow,
    flexShrink,
    justifyContent,
    justifyItems,
    justifySelf,
    alignContent,
    alignItems,
    alignSelf,
    ...rest
  } = props;

  return {
    props: rest,
    className: _flexProps(
      flex,
      flexDirection,
      flexWrap,
      flexGrow,
      flexShrink,
      justifyContent,
      justifyItems,
      justifySelf,
      alignContent,
      alignItems,
      alignSelf,
    ),
  };
};

const _flexProps = memoize(
  (
    flex: IFlexProps['flex'],
    flexDirection: IFlexProps['flexDirection'],
    flexWrap: IFlexProps['flexWrap'],
    flexGrow: IFlexProps['flexGrow'],
    flexShrink: IFlexProps['flexShrink'],
    justifyContent: IFlexProps['justifyContent'],
    justifyItems: IFlexProps['justifyItems'],
    justifySelf: IFlexProps['justifySelf'],
    alignContent: IFlexProps['alignContent'],
    alignItems: IFlexProps['alignItems'],
    alignSelf: IFlexProps['alignSelf'],
  ) => {
    return cn({
      [`sl-flex-${flex}`]: flex !== void 0,
      [`sl-flex-${flexDirection}`]: flexDirection !== void 0,
      [`sl-flex-${flexWrap === true ? 'wrap' : flexWrap}`]: flexWrap !== void 0,
      [`sl-flex-grow${flexGrow === true ? '' : '-0'}`]: flexGrow !== void 0,
      [`sl-flex-shrink${flexShrink === true ? '' : '-0'}`]: flexShrink !== void 0,

      [`sl-justify-${justifyContent}`]: justifyContent !== void 0,
      [`sl-justify-items-${justifyItems}`]: justifyItems !== void 0,
      [`sl-justify-self-${justifySelf}`]: justifySelf !== void 0,

      [`sl-content-${alignContent}`]: alignContent !== void 0,
      [`sl-items-${alignItems}`]: alignItems !== void 0,
      [`sl-self-${alignSelf}`]: alignSelf !== void 0,
    });
  },
  { maxAge: Infinity, equals },
);

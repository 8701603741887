import * as React from 'react';

const useScrollTo = <T extends Element>(ref: React.MutableRefObject<T>): [(val?: boolean) => void] => {
  const scrollTo = React.useCallback(() => {
    if (ref.current) {
      ref.current!.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
    }
  }, [ref]);

  return [scrollTo];
};

export default useScrollTo;

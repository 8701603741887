import { useId } from '@react-aria/utils';
import { useDOMRef, useValueEffect } from '@react-spectrum/utils';
import { TabListState } from '@react-stately/tabs';
import { DOMRef } from '@react-types/shared';
import * as React from 'react';

import { NoSsr } from '../NoSsr';
import { Stack } from '../Stack';
import { TabsContext } from './TabsContext';
import { AppearanceVals, OrientationVals, VerticalStateAlignment } from './variants';

export type TabsOwnProps = {
  /**
   * Must contain `TabList` and `TabPanel` components.
   */
  children: React.ReactNode;

  /**
   * The current selected tab's `id`.
   * If provided and `onChange` is not provided, this value acts as a default value.
   */
  selectedId?: string;

  /**
   * Callback for when the selected tab state changes.
   * When provided you are expected to manage `selectedId` (controlled mode).
   */
  onChange?: (selectedId: string) => void;

  /**
   * Defines the direction the tabs are displayed. Defaults to `horizontal`.
   */
  orientation?: OrientationVals;

  /**
   * Alter the tabs overall appearance - defaults to `minimal`.
   */
  appearance?: AppearanceVals;

  activeStateAlignment?: VerticalStateAlignment;
};

export const Tabs = React.forwardRef((props: TabsOwnProps, ref: DOMRef<HTMLDivElement>) => {
  const { children, appearance = 'minimal', orientation = 'horizontal' } = props;

  const domRef = useDOMRef(ref);
  const tablistRef = React.useRef<HTMLDivElement>();
  const wrapperRef = React.useRef<HTMLDivElement>();

  const [collapse, setCollapse] = useValueEffect(false);
  const [selectedTab, setSelectedTab] = React.useState<HTMLElement>();
  const [tabListState, setTabListState] = React.useState<TabListState<any>>(null);

  React.useEffect(() => {
    if (tablistRef.current) {
      let selectedTab: HTMLElement = tablistRef.current.querySelector(`[data-key="${tabListState?.selectedKey}"]`);

      if (selectedTab != null) {
        setSelectedTab(selectedTab);
      }
    }
    // collapse is in the dep array so selectedTab can be updated for TabLine positioning
  }, [children, tabListState?.selectedKey, collapse, tablistRef]);

  // TODO: when we add logic to collapse overflow tabs
  // const direction: 'ltr' | 'rtl' = 'ltr';
  // const checkShouldCollapse = React.useCallback(() => {
  //   const computeShouldCollapse = () => {
  //     if (wrapperRef.current) {
  //       const tabsComponent = wrapperRef.current;
  //       const tabs = tablistRef.current.querySelectorAll('[role="tab"]');
  //       const lastTab = tabs[tabs.length - 1];

  //       // @ts-expect-error
  //       const end = direction === 'rtl' ? 'left' : 'right';
  //       const farEdgeTabList = tabsComponent.getBoundingClientRect()[end];
  //       const farEdgeLastTab = lastTab?.getBoundingClientRect()[end];

  //       // @ts-expect-error
  //       const shouldCollapse = direction === 'rtl' ? farEdgeLastTab < farEdgeTabList : farEdgeTabList < farEdgeLastTab;

  //       return shouldCollapse;
  //     }
  //   };

  //   if (orientation !== 'vertical') {
  //     setCollapse(function* () {
  //       // Make Tabs render in non-collapsed state
  //       yield false;

  //       // Compute if Tabs should collapse and update
  //       yield computeShouldCollapse();
  //     });
  //   }
  // }, [tablistRef, wrapperRef, direction, orientation, setCollapse]);

  // React.useEffect(() => {
  //   checkShouldCollapse();
  // }, [children, checkShouldCollapse]);

  // useResizeObserver({ref: wrapperRef, onResize: checkShouldCollapse});

  let tabPanelProps = {
    'aria-labelledby': undefined,
  };

  // When the tabs are collapsed, the tabPanel should be labelled by the Picker button element.
  let collapsibleTabListId = useId();
  if (collapse && orientation !== 'vertical') {
    tabPanelProps['aria-labelledby'] = collapsibleTabListId;
  }

  return (
    <NoSsr>
      <TabsContext.Provider
        value={{
          tabsProps: { ...props, orientation, appearance },
          tabState: { tabListState, setTabListState, selectedTab, collapse },
          refs: { tablistRef, wrapperRef },
          tabPanelProps,
        }}
      >
        <Stack
          ref={domRef}
          w="full"
          direction={orientation === 'vertical' ? 'horizontal' : 'vertical'}
          h={orientation === 'vertical' ? 'full' : 'auto'}
          spacing={1}
        >
          {props.children}
        </Stack>
      </TabsContext.Provider>
    </NoSsr>
  );
});

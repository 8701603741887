import { FocusableProvider } from '@react-aria/focus';
import { useTooltip, useTooltipTrigger } from '@react-aria/tooltip';
import { mergeProps } from '@react-aria/utils';
import { TooltipTriggerState, useTooltipTriggerState } from '@react-stately/tooltip';
import * as React from 'react';
import {
  CSSProperties,
  forwardRef,
  LegacyRef,
  MutableRefObject,
  ReactElement,
  RefObject,
  useCallback,
  useRef,
} from 'react';

import { useOverlayPosition, useThemeIsDark } from '../../hooks';
import { MaybeRenderProp, Placement, runIfFn } from '../../utils';
import { Box } from '../Box';
import { Icon, IIconProps } from '../Icon';
import { Overlay } from '../Overlay';

const TOOLTIP_DELAY = 500;
const TOOLTIP_OFFSET = 10;

/**
 * Expose a select set of props to customize the popover wrapper
 */
type TooltipBoxProps = {};

export type TooltipProps = {
  /**
   * The element that will trigger the Tooltip. Should be a button in most cases. Can use a function for more control.
   */
  renderTrigger: MaybeRenderProp<{ isOpen: boolean }, ReactElement>;

  /**
   * The content to render in the Tooltip. Can use a function to access Tooltip state and for more control over close.
   */
  children: MaybeRenderProp<{ close: () => void }>;

  /**
   * The ref of the element the Tooltip should visually attach itself to.
   */
  triggerRef?: MutableRefObject<undefined>;

  /**
   * The delay time for the tooltip to show up, in milliseconds. Defaults to 500.
   */
  delay?: number;

  /**
   * Whether the tooltip should be disabled, independent from the trigger.
   */
  isDisabled?: boolean;

  /**
   * Whether the Tooltip is open (controlled mode).
   */
  isOpen?: boolean;

  /**
   * Whether to default the Tooltip to open (uncontrolled mode).
   */
  defaultOpen?: boolean;

  /**
   * Called when the Tooltip opens.
   */
  onOpen?: () => void;

  /**
   * Called when the Tooltip closes.
   */
  onClose?: () => void;

  /**
   * The placement of the Tooltip overlay with respect to its trigger element. Defaults to `bottom`.
   */
  placement?: Placement;

  /**
   * Whether the element should flip its orientation (e.g. top to bottom or left to right) when there is insufficient
   * room for it to render completely. Defaults to true.
   */
  shouldFlip?: boolean;

  /**
   * The additional offset applied along the main axis between the element and its anchor element.
   */
  offset?: number;

  /**
   * The additional offset applied along the cross axis between the element and its anchor element.
   */
  crossOffset?: number;

  /**
   * Pass true to render the tooltip without an arrow.
   */
  hideArrow?: boolean;
} & TooltipBoxProps;

export const Tooltip = (props: TooltipProps) => {
  const {
    renderTrigger,
    children,
    placement = 'bottom',
    isOpen,
    defaultOpen,
    onOpen,
    onClose,
    delay = TOOLTIP_DELAY,
    offset = TOOLTIP_OFFSET,
    crossOffset,
    isDisabled,
    shouldFlip = true,
    hideArrow,
  } = props;

  const overlayRef = useRef();

  const _triggerRef = useRef();
  const tooltipTriggerRef = props.triggerRef || _triggerRef;

  const onOpenChange = useCallback(
    $isOpen => {
      if ($isOpen && onOpen) onOpen();
      if (!$isOpen && onClose) onClose();
    },
    [onClose, onOpen],
  );

  let state = useTooltipTriggerState({
    isOpen,
    defaultOpen,
    delay,
    isDisabled,
    onOpenChange,
  });

  // Get props for the trigger and overlay. This also handles
  // hiding the overlay when a parent element of the trigger scrolls
  // (which invalidates the popover positioning).
  let { triggerProps, tooltipProps } = useTooltipTrigger(
    {
      isDisabled,
    },
    state,
    tooltipTriggerRef,
  );

  // Get popover positioning props relative to the trigger
  let {
    overlayProps: positionProps,
    arrowProps,
    arrowIcon,
  } = useOverlayPosition({
    targetRef: tooltipTriggerRef,
    overlayRef,
    placement,
    offset,
    crossOffset,
    shouldFlip,
    isOpen: state.isOpen,
    onClose,
  });

  const triggerElem = runIfFn(renderTrigger, { isOpen: state.isOpen });

  return (
    <FocusableProvider {...triggerProps} ref={tooltipTriggerRef}>
      {React.cloneElement(triggerElem, {
        ...mergeProps(triggerElem.props, triggerProps),
        ref: tooltipTriggerRef,
      })}

      <Overlay isOpen={state.isOpen}>
        <TooltipWrapper {...tooltipProps} {...positionProps} ref={overlayRef} state={state}>
          {runIfFn(children, { close: state.close })}
          {!hideArrow && <TooltipArrow icon={arrowIcon} style={arrowProps.style} />}
        </TooltipWrapper>
      </Overlay>
    </FocusableProvider>
  );
};

type TooltipWrapperProps = Pick<TooltipProps, 'children'> &
  TooltipBoxProps & {
    state: TooltipTriggerState;
    style?: CSSProperties;
  };

const TooltipWrapper = forwardRef(
  ({ children, state, ...otherProps }: TooltipWrapperProps, ref: RefObject<HTMLElement>) => {
    const isDark = useThemeIsDark();

    // Handle interacting outside the dialog and pressing
    // the Escape key to close the modal. Using expect-error so that we know when to remove this
    // @ts-expect-error useTooltip typing is incorrect, state is expected
    let { tooltipProps } = useTooltip({ children }, state);

    const { color, ...containerProps } = mergeProps(tooltipProps, otherProps);

    return (
      <Box
        {...containerProps}
        className="sl-tooltip"
        ref={ref as LegacyRef<HTMLDivElement>}
        data-testid="tooltip"
        data-theme="dark"
        bg={isDark ? 'canvas-dialog' : 'canvas-pure'}
      >
        {children}
      </Box>
    );
  },
);

function TooltipArrow({ icon, style }: { icon: IIconProps['icon']; style?: CSSProperties }) {
  const isDark = useThemeIsDark();

  return (
    <Box className="sl-tooltip_tip" pos="absolute" color={isDark ? 'canvas-dialog' : 'canvas-pure'} style={style}>
      <Icon icon={icon} />
    </Box>
  );
}

import { KeyboardDelegate } from '@react-types/shared';
import { Key } from 'react';

import { MenuCollection } from './MenuCollection';
import { MenuState } from './useMenuState';

export class MenuKeyboardDelegate implements KeyboardDelegate {
  collection: MenuCollection;
  expandedKeys: Set<Key>;
  selectionManager: MenuState['selectionManager'];
  toggleKey: MenuState['toggleKey'];
  collapseKey: MenuState['collapseKey'];

  constructor(state: MenuState) {
    this.expandedKeys = state.expandedKeys;
    this.collection = state.collection as MenuCollection;
    this.selectionManager = state.selectionManager;
    this.toggleKey = state.toggleKey;
    this.collapseKey = state.collapseKey;
  }

  getKeyRightOf(key) {
    const { selectionManager, collection, toggleKey } = this;

    const item = collection.getItem(key);
    if (!item || !item.hasChildNodes) return;

    toggleKey(key);
    selectionManager.setFocused(true);

    // return the first key in the nested menu
    const rightKey = this.getFirstKey();

    return rightKey;
  }

  getKeyLeftOf(key) {
    const { selectionManager, collection, collapseKey } = this;

    const item = collection.getItem(key);
    if (!item) return;

    let parent = collection.getItem(item.parentKey);
    let parentKey: Key | null = null;
    while (parentKey === null) {
      if (!parent) return null;

      if (parent.menuLevel === item.menuLevel - 1) {
        parentKey = parent.key;
      } else {
        parent = collection.getItem(parent.parentKey);
      }
    }

    if (parentKey) {
      collapseKey(parentKey);
    }

    selectionManager.setFocusedKey(parentKey);
    selectionManager.setFocused(true);

    // return the parent key
    return parentKey;
  }

  getKeyAbove(key) {
    const { collection } = this;

    const deepestExpandedKey = this.getDeepestExpandedKey();

    let keyBefore = collection.getKeyBefore(key);
    while (keyBefore !== null) {
      let item = collection.getItem(keyBefore);

      if (
        item?.type === 'item' &&
        !item.isDisabled &&
        // only navigate up/down through items in this menu (not nested or parent menus)
        item.parentMenuItemKey === deepestExpandedKey
      ) {
        return keyBefore;
      }

      keyBefore = collection.getKeyBefore(keyBefore);
    }

    return null;
  }

  getKeyBelow(key) {
    const { collection } = this;

    const deepestExpandedKey = this.getDeepestExpandedKey();

    let keyBelow = collection.getKeyAfter(key);

    while (keyBelow !== null) {
      const item = collection.getItem(keyBelow);

      if (
        item?.type === 'item' &&
        !item.isDisabled &&
        // only navigate up/down through items in this menu (not nested or parent menus)
        item.parentMenuItemKey === deepestExpandedKey
      ) {
        return keyBelow;
      }

      keyBelow = collection.getKeyAfter(keyBelow);
    }

    return null;
  }

  getFirstKey() {
    const { collection } = this;

    const deepestExpandedKey = this.getDeepestExpandedKey();

    let key = collection.getFirstKey();
    while (key !== null) {
      const item = collection.getItem(key);

      if (item?.type === 'item' && !item.isDisabled && item.parentMenuItemKey === deepestExpandedKey) {
        return key;
      }

      key = collection.getKeyAfter(key);
    }

    return null;
  }

  getLastKey() {
    const { collection } = this;

    const deepestExpandedKey = this.getDeepestExpandedKey();

    let key = collection.getLastKey();
    while (key !== null) {
      const item = collection.getItem(key);

      if (item?.type === 'item' && !item.isDisabled && item.parentMenuItemKey === deepestExpandedKey) {
        return key;
      }

      key = collection.getKeyBefore(key);
    }

    return null;
  }

  private getDeepestExpandedKey(): Key | null {
    const { expandedKeys } = this;

    const keyArray = Array.from(expandedKeys.keys());
    return keyArray[keyArray.length - 1] || null;
  }
}

import { IntentVals } from '../../enhancers';
import { BoxOwnProps } from '../Box/types';

export type AppearanceVals = 'default' | 'minimal';

export const variants: Record<AppearanceVals, Record<IntentVals, Partial<BoxOwnProps>>> = {
  default: {
    default: {
      borderColor: {
        default: 'input',
        // TODO: when have more sophisticated theme val system, slightly darker color on hover as in ui designs
        // hover: 'input',
        focus: 'primary',
      },
    },
    success: {
      borderColor: {
        default: 'success',
        focus: 'success-dark',
      },
    },
    warning: {
      borderColor: {
        default: 'warning',
        focus: 'warning-dark',
      },
    },
    danger: {
      borderColor: {
        default: 'danger',
        focus: 'danger-dark',
      },
    },
  },
  minimal: {
    default: {
      borderColor: {
        default: 'transparent',
        hover: 'input',
        focus: 'primary',
      },
    },
    success: {
      borderColor: {
        default: 'transparent',
        hover: 'success',
        focus: 'success-dark',
      },
    },
    warning: {
      borderColor: {
        default: 'transparent',
        hover: 'warning',
        focus: 'warning-dark',
      },
    },
    danger: {
      borderColor: {
        default: 'transparent',
        hover: 'danger',
        focus: 'danger-dark',
      },
    },
  },
};

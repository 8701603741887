import { Node } from '@react-types/shared';
import { Key } from 'react';

export const accumulateCollectionKeysByProp = (targetProp: string, targetValue: any) => {
  let keysHash = '[]';
  let keys: Key[] = [];

  const accumulate = (nodes: Iterable<Node<unknown>>, newKeys: Key[]) => {
    for (const node of nodes) {
      if (node.props && node.props[targetProp] === targetValue) {
        newKeys.push(node.key);
      }

      if (node.hasChildNodes) {
        accumulate(node.childNodes, newKeys);
      }
    }
  };

  const accumulator = (nodes: Iterable<Node<unknown>>) => {
    let newKeys: Key[] = [];

    accumulate(nodes, newKeys);

    let newKeysHash = JSON.stringify(newKeys);
    if (keysHash !== newKeysHash) {
      keysHash = newKeysHash;
      keys = newKeys;
    }

    return keys;
  };

  return accumulator;
};

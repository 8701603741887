import cn from 'clsx';
import * as React from 'react';

import { useMosaicContext, useThemeIsDark } from '../../hooks';
import { Box } from '../Box';
import { PolymorphicComponentProps } from '../Box/types';

export type ImageOwnProps = {
  src: string;
  invertOnDark?: boolean;
};

const defaultElement = 'img';

export type ImageProps<E extends React.ElementType = typeof defaultElement> = PolymorphicComponentProps<
  E,
  ImageOwnProps
>;

export function Image<E extends React.ElementType = typeof defaultElement>({
  className,
  invertOnDark,
  ...props
}: ImageProps<E>) {
  const {
    componentOverrides: { Image: ImageOverride },
  } = useMosaicContext();

  const isDark = useThemeIsDark();

  return (
    <Box
      as={ImageOverride || defaultElement}
      className={cn('sl-image', className, {
        'sl-image--inverted': invertOnDark && isDark,
      })}
      {...props}
    />
  );
}

import cn from 'clsx';
import * as React from 'react';

import { Box } from '../Box';
import { IBoxHTMLAttributes } from '../Box/types';

export interface ICodeProps extends IBoxHTMLAttributes {}

export function Code({ className, ...props }: ICodeProps) {
  return (
    <Box
      as="code"
      className={cn(className)}
      fontWeight="medium"
      fontFamily="mono"
      bg="code"
      color="on-code"
      border
      py={0.5}
      px={1}
      mx={0.5}
      rounded
      style={{ fontSize: '0.8125em' }}
      {...props}
    />
  );
}

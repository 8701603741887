import type { IconProp } from '@fortawesome/fontawesome-svg-core';

import { IntentVals } from '../../enhancers';
import { BoxOwnProps } from '../Box/types';

export const iconVariants: { intent: Record<IntentVals, IconProp> } = {
  intent: {
    default: 'info-circle',
    success: 'check-circle',
    warning: 'exclamation-circle',
    danger: 'exclamation-circle',
  },
};

export const variants: Record<'default' | 'outline', Record<IntentVals, Partial<BoxOwnProps>>> = {
  default: {
    default: {
      border: 2,
      borderColor: 'transparent',
      rounded: 'lg',
      bg: {
        default: 'primary-tint',
      },
    },
    success: {
      bg: {
        default: 'success-tint',
      },
    },
    warning: {
      bg: {
        default: 'warning-tint',
      },
    },
    danger: {
      bg: {
        default: 'danger-tint',
      },
    },
  },
  outline: {
    default: {
      border: 2,
      rounded: 'xl',
      borderColor: 'primary',
    },
    success: {
      borderColor: 'success',
    },
    warning: {
      borderColor: 'warning',
    },
    danger: {
      borderColor: 'danger',
    },
  },
};

import { OverlayProvider as ReactAriaOverlayProvider } from '@react-aria/overlays';
import { SSRProvider as ReactAriaProvider } from '@react-aria/ssr';
import cn from 'clsx';
import * as React from 'react';
import { CSSProperties, useRef } from 'react';

import { useId } from '../../hooks';
import { defaultMosaicContext, MosaicContext, MosaicContextProps } from './MosaicContext';

export type ProviderProps = Partial<MosaicContextProps> & {
  children: React.ReactNode;
  className?: string;
  style?: CSSProperties;
};

function MosaicProvider(props: ProviderProps) {
  return (
    <ReactAriaProvider>
      <MosaicInnerProvider {...props} />
    </ReactAriaProvider>
  );
}

function MosaicInnerProvider({ children, className, style, ...mosaicContext }: ProviderProps) {
  const providerId = `mosaic-provider-${useId()}`;
  const providerRef = useRef<HTMLDivElement>();

  return (
    <div className={cn(className)} style={style} id={providerId} ref={providerRef}>
      <ReactAriaOverlayProvider className={cn(className)} style={style}>
        <MosaicContext.Provider
          value={Object.assign({}, defaultMosaicContext, mosaicContext, { providerId, providerRef })}
        >
          {children}
        </MosaicContext.Provider>
      </ReactAriaOverlayProvider>
    </div>
  );
}

export { MosaicProvider as Provider };

import deepmerge from 'deepmerge';
import _get from 'lodash.get';

import { defaultTheme } from '../utils/theme';
import { useThemeStore } from './use-theme-store';

/**
 * Pass a lodash style property path to pluck a specific theme property out. This helps with performance
 * if theme is being updated, since calling component will only re-render when the particular theme value
 * that is subscribed to changes.
 */
export const useTheme = (property?: string) => {
  const themeValue = useThemeStore(state => _get(deepmerge(defaultTheme, state.theme), property));

  const setColor = useThemeStore(state => state.setColor);
  const reset = useThemeStore(state => state.reset);

  return { themeValue, setColor, reset };
};

import { PartialNode } from '@react-stately/collections';
import { SectionProps as _SectionProps } from '@react-types/shared';
import * as React from 'react';
import { Key, ReactElement } from 'react';

import { generateKey } from './keys';

export type SectionProps<T> = _SectionProps<T> & { id?: Key };

/**
 * Pulled from https://github.com/adobe/react-spectrum/blob/main/packages/%40react-stately/collections/src/Section.ts
 * with some minor adjustments.
 */

export function Section<T>(props: SectionProps<T>): ReactElement {
  return null;
}

// See examples of getCollectionNode https://github.com/adobe/react-spectrum/search?q=getCollectionNode
Section.getCollectionNode = function* getCollectionNode<T>(props: SectionProps<T>, context): Generator<PartialNode<T>> {
  let { children, id, title, items } = props;

  yield {
    key: typeof id !== 'undefined' ? id : `${generateKey(items)}-section`,
    type: 'section',
    hasChildNodes: true,
    props,
    rendered: title,
    'aria-label': props['aria-label'],
    *childNodes() {
      if (typeof children === 'function') {
        if (!items) {
          throw new Error('props.children was a function but props.items is missing');
        }

        for (let item of items) {
          yield {
            value: item,
            renderer: children,
          };
        }
      } else {
        let items: PartialNode<T>[] = [];
        React.Children.forEach(children, child => {
          items.push({
            element: child,
          });
        });

        yield* items;
      }
    },
  };
};

import cn from 'clsx';
import memoize from 'nano-memoize';
import equals from 'react-fast-compare';

import { computePseudoClasses, Pseudo } from './pseudo';
import { NegativeSpaceVals, SpaceVals } from './spacing';
import { EnhancerFn } from './types';

export type TransitionDelayVals = 75 | 150 | 300 | 500 | 1000;
export type TransitionDurationVals = 75 | 150 | 300 | 500 | 1000;

export type TransformProps = {
  transform?: true;
  transition?: true;
  transitionDelay?: TransitionDelayVals;
  transitionDuration?: TransitionDurationVals;
  translateX?: Pseudo<SpaceVals | NegativeSpaceVals, 'hover'>;
  translateY?: Pseudo<SpaceVals | NegativeSpaceVals, 'hover'>;
};

export const transformPropNames: Array<keyof TransformProps> = [
  'transform',
  'transition',
  'transitionDelay',
  'transitionDuration',
  'translateX',
  'translateY',
];

export const transformProps: EnhancerFn<TransformProps> = (props: TransformProps) => {
  const { transform, transition, transitionDelay, transitionDuration, translateX, translateY, ...rest } = props;

  return {
    props: rest,
    className: _transformProps(transform, transition, transitionDelay, transitionDuration, translateX, translateY),
  };
};

const _transformProps = memoize(
  (
    transform: TransformProps['transform'],
    transition: TransformProps['transition'],
    transitionDelay: TransformProps['transitionDelay'],
    transitionDuration: TransformProps['transitionDuration'],
    translateX: TransformProps['translateX'],
    translateY: TransformProps['translateY'],
  ) => {
    return cn(
      {
        [`sl-transform`]: transform,
        [`sl-transition`]: transition,
        [`sl-delay-${transitionDelay}`]: transitionDelay,
        [`sl-duration-${transitionDuration}`]: transitionDuration,
      },
      computePseudoClasses('translate-x', translateX),
      computePseudoClasses('translate-y', translateY),
    );
  },
  { maxAge: Infinity, equals },
);

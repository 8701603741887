import cn from 'clsx';
import memoize from 'nano-memoize';
import equals from 'react-fast-compare';

import { SpaceVals } from './spacing';
import { EnhancerFn } from './types';

export type HeightVals = SpaceVals | 'auto' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | 'full' | 'screen';
export type MaxHeightVals = 'full' | 'screen';
export type MinHeightVals = 'full' | 'screen';

export type WidthVals =
  | SpaceVals
  | 'auto'
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | '2xl'
  | '3xl'
  | '1/2'
  | '1/3'
  | '2/3'
  | '1/4'
  | '2/4'
  | '3/4'
  | '1/5'
  | '2/5'
  | '3/5'
  | '4/5'
  | '1/6'
  | '2/6'
  | '3/6'
  | '4/6'
  | '5/6'
  | 'full'
  | 'screen'
  | 'min'
  | 'max';
export type MaxWidthVals = 'none' | 'full' | 'min' | 'max' | 'prose';
export type MinWidthVals = 'full' | 'min' | 'max';

export interface IHeightProps {
  h?: HeightVals;
  maxH?: MaxHeightVals;
  minH?: MinHeightVals;
}

export interface IWidthProps {
  w?: WidthVals;
  maxW?: MaxWidthVals;
  minW?: MinWidthVals;
}

export interface ISizeProps extends IHeightProps, IWidthProps {}

export const sizePropNames: Array<keyof ISizeProps> = ['h', 'maxH', 'minH', 'maxW', 'minW', 'w'];

export const sizeProps: EnhancerFn<ISizeProps> = (props: ISizeProps) => {
  const { h, maxH, minH, w, maxW, minW, ...rest } = props;

  return { props: rest, className: _sizeProps(h, maxH, minH, w, maxW, minW) };
};

const _sizeProps = memoize(
  (
    h: ISizeProps['h'],
    maxH: ISizeProps['maxH'],
    minH: ISizeProps['minH'],
    w: ISizeProps['w'],
    maxW: ISizeProps['maxW'],
    minW: ISizeProps['minW'],
  ) => {
    return cn({
      [`sl-w-${w}`]: w !== void 0,
      [`sl-max-w-${maxW}`]: maxW !== void 0,
      [`sl-min-w-${minW}`]: minW !== void 0,

      [`sl-h-${h}`]: h !== void 0,
      [`sl-max-h-${maxH}`]: maxH !== void 0,
      [`sl-min-h-${minH}`]: minH !== void 0,
    });
  },
  { maxAge: Infinity, equals },
);

import { useDOMRef } from '@react-spectrum/utils';
import { DOMRef } from '@react-types/shared';
import * as React from 'react';
import { forwardRef } from 'react';

type WithDomRefProps = {
  children: React.FunctionComponentElement<any>;
};

export const WithDomRef = forwardRef(function WithDomRef({ children }: WithDomRefProps, ref: DOMRef<HTMLElement>) {
  return React.cloneElement(children, {
    ref: useDOMRef(ref),
  });
});

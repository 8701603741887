import { useTextField } from '@react-aria/textfield';
import { mergeProps } from '@react-aria/utils';
import { TextInputBase } from '@react-types/shared';
import cn from 'clsx';
import * as React from 'react';
import { forwardRef, memo, RefObject, useCallback, useLayoutEffect, useRef } from 'react';

import { IntentVals } from '../../enhancers';
import { splitBoxProps } from '../../utils';
import { Box } from '../Box';
import { BoxOwnProps, IBoxHTMLAttributes, PolymorphicComponentProps } from '../Box/types';
import { AppearanceVals, fontSizes, sizes, variants } from './variants';

export type TextareaOwnProps = TextInputBase & {
  size?: 'sm' | 'md' | 'lg';
  intent?: IntentVals;
  disabled?: boolean;
  readOnly?: boolean;
  required?: boolean;
  appearance?: AppearanceVals;
};

export type TextareaProps<E extends React.ElementType = typeof defaultElement> = PolymorphicComponentProps<
  E,
  TextareaOwnProps
>;

const defaultElement = 'textarea';

export const Textarea: <E extends React.ElementType = typeof defaultElement>(
  props: TextareaProps<E> & { ref?: RefObject<HTMLInputElement> },
) => JSX.Element = memo(
  forwardRef(
    <E extends React.ElementType>(
      {
        intent = 'default',
        size = 'md',
        resize = 'y',
        appearance = 'default',
        className,
        disabled,
        required,
        readOnly,
        ...props
      }: TextareaProps<E>,
      ref: RefObject<HTMLInputElement>,
    ) => {
      const fallbackRef = useRef();
      const textAreaRef = ref || fallbackRef;

      const { inputProps } = useTextField(
        { isDisabled: disabled, isRequired: required, isReadOnly: readOnly, ...props },
        textAreaRef,
      );
      const { matchedProps, remainingProps } = splitBoxProps(props);

      const stateProps: Partial<BoxOwnProps> = {
        ...variants.default.default,
        ...variants.default[intent],
        ...variants[appearance].default,
        ...variants[appearance][intent],
      };

      let onResizeChange = useCallback(() => {
        let input = textAreaRef.current;
        input.style.minHeight = `${input.scrollHeight + 2}px`;
        input.style.minWidth = `${input.scrollWidth + 2}px`;
      }, [textAreaRef]);

      useLayoutEffect(() => {
        if (textAreaRef.current) {
          onResizeChange();
        }
      }, [onResizeChange, textAreaRef]);

      let disabledProps = {};
      if (disabled) {
        disabledProps = {
          bg: 'canvas-100',
          color: 'muted',
          cursor: 'not-allowed',
        };
      }

      let readOnlyProps: Partial<IBoxHTMLAttributes & BoxOwnProps> = {};
      if (readOnly) {
        readOnlyProps.tabIndex = -1;

        if (appearance === 'minimal') {
          readOnlyProps.borderColor = 'transparent';
        }
      }

      const textareaProps = mergeProps(inputProps, { color: undefined });

      return (
        <Box className={cn('sl-textarea', className)} pos="relative" {...matchedProps}>
          <Box
            ref={textAreaRef}
            as={defaultElement}
            pl={sizes[size].padding}
            pr={sizes[size].padding}
            pt={2}
            fontSize={fontSizes[size]}
            rounded
            resize={resize}
            rows={sizes[size].rows}
            border
            w="full"
            disabled={disabled}
            readOnly
            pos="relative"
            {...textareaProps}
            {...remainingProps}
            {...readOnlyProps}
            {...stateProps}
            {...disabledProps}
          />
        </Box>
      );
    },
  ),
);

import * as React from 'react';
import { memo } from 'react';

import { ITypographyProps } from '../../enhancers';
import { Box } from '../Box';
import { BoxProps } from '../Box/types';

export type HeadingSizeVals = 1 | 2 | 3 | 4;

export type HeadingOwnProps = {
  size: HeadingSizeVals;
};

export type HeadingAs = 'h1' | 'h2' | 'h3' | 'h4';
export type HeadingProps = HeadingOwnProps & BoxProps<HeadingAs>;

const sizes: Record<HeadingSizeVals, Partial<ITypographyProps> & { as: HeadingAs }> = {
  1: {
    as: 'h1',
    fontSize: '5xl',
    fontWeight: 'bold',
    lineHeight: 'tight',
  },
  2: {
    as: 'h2',
    fontSize: '4xl',
    fontWeight: 'bold',
    lineHeight: 'tight',
  },
  3: {
    as: 'h3',
    fontSize: '2xl',
    fontWeight: 'semibold',
    lineHeight: 'snug',
  },
  4: {
    as: 'h4',
    fontSize: 'paragraph',
    fontWeight: 'semibold',
    lineHeight: 'snug',
  },
};

export const Heading = memo(function Heading({ size, ...props }: HeadingProps) {
  return <Box fontFamily="prose" fontWeight="bold" color="heading" {...sizes[size]} {...props} />;
});

import { DOMRef } from '@react-types/shared';
import * as React from 'react';
import { ReactNode } from 'react';
import ReactDOM from 'react-dom';

import { useMosaicContext } from '../../hooks';
import { WithDomRef } from '../../utils';
import { Box } from '../Box';

export type OverlayProps = {
  children: ReactNode;
  isOpen?: boolean;
  container?: Element;
};

function Overlay(props: OverlayProps, ref: DOMRef<HTMLDivElement>) {
  let { children, isOpen, container } = props;

  const { providerRef } = useMosaicContext();

  let mountOverlay = isOpen;
  if (!mountOverlay || typeof window === 'undefined') {
    // Don't bother showing anything if we don't have to.
    return null;
  }

  let contents = (
    <WithDomRef ref={ref}>
      <Box bg="transparent">{children}</Box>
    </WithDomRef>
  );

  const portalContainer = container || providerRef?.current || document.body;

  return ReactDOM.createPortal(contents, portalContainer);
}

let _Overlay = React.forwardRef(Overlay);

export { _Overlay as Overlay };

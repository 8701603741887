/**
 * Original credit to https://github.com/adobe/react-spectrum/blob/main/packages/%40react-aria/visually-hidden/src/VisuallyHidden.tsx
 * Modified `styles` a little bit to fix issues with the dismiss button introducing a 1px height and causing temp scrollbars on screen
 */

import { useFocus } from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';
import * as React from 'react';

interface VisuallyHiddenProps extends React.HTMLAttributes<HTMLElement> {
  /** The content to visually hide. */
  children?: React.ReactNode;

  /**
   * The element type for the container.
   * @default 'div'
   */
  elementType?: string | React.JSXElementConstructor<any>;

  /** Whether the element should become visible on focus, for example skip links. */
  isFocusable?: boolean;
}

const styles: React.CSSProperties = {
  border: 0,
  clip: 'rect(0 0 0 0)',
  height: 1,
  margin: '-1px',
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
  width: 1,
  whiteSpace: 'nowrap',
};

interface VisuallyHiddenAria {
  visuallyHiddenProps: React.HTMLAttributes<HTMLElement>;
}

/**
 * Provides props for an element that hides its children visually
 * but keeps content visible to assistive technology.
 */
export function useVisuallyHidden(props: VisuallyHiddenProps = {}): VisuallyHiddenAria {
  let { style, isFocusable } = props;

  let [isFocused, setFocused] = React.useState(false);
  let { focusProps } = useFocus({
    isDisabled: !isFocusable,
    onFocusChange: setFocused,
  });

  // If focused, don't hide the element.
  let combinedStyles = React.useMemo(() => {
    if (isFocused) {
      return style;
    } else if (style) {
      return { ...styles, ...style };
    } else {
      return styles;
    }
  }, [isFocused, style]);

  return {
    visuallyHiddenProps: {
      ...focusProps,
      style: combinedStyles,
    },
  };
}

/**
 * VisuallyHidden hides its children visually, while keeping content visible
 * to screen readers.
 */
export function VisuallyHidden(props: VisuallyHiddenProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let { children, elementType: Element = 'div', isFocusable, style, ...otherProps } = props;
  let { visuallyHiddenProps } = useVisuallyHidden(props);

  return <Element {...mergeProps(otherProps, visuallyHiddenProps)}>{children}</Element>;
}

import { getInteractionModality } from '@react-aria/interactions';
import { useListBox, useListBoxSection, useOption } from '@react-aria/listbox';
import { HiddenSelect, useSelect } from '@react-aria/select';
import { useSeparator } from '@react-aria/separator';
import { mergeProps } from '@react-aria/utils';
import useSize from '@react-hook/size';
import { useFocusableRef, useUnwrapDOMRef } from '@react-spectrum/utils';
import { SelectState, useSelectState } from '@react-stately/select';
import { FocusableRefValue, Node } from '@react-types/shared';
import cn from 'clsx';
import * as React from 'react';
import { forwardRef, Key, RefObject, useCallback, useMemo, useRef, useState } from 'react';

import { WidthVals } from '../../enhancers';
import { useCollectionKeyAccumulator, useThemeIsDark } from '../../hooks';
import { Box } from '../Box';
import { FieldButton } from '../Button';
import { Internal_MenuItemRow } from '../Menu/MenuItemRow';
import { DismissButton } from '../Overlay';
import { Popover } from '../Popover';
import { isSelectAction, SelectAction } from './SelectAction';
import { isSelectOption, SelectOption } from './SelectOption';
import { isSelectSection, SelectSection } from './SelectSection';
import { SelectItemProps, SelectProps, SelectSectionProps } from './types';

function Select(
  {
    flexGrow,
    flex,
    w,
    size,
    triggerTextPrefix,
    options,
    value,
    defaultValue,
    onChange,
    isClearable,
    placeholder = 'select an option',
    appearance,
    onOpen,
    onClose,
    isDisabled,
    icon,
    ...props
  }: SelectProps,
  ref: RefObject<FocusableRefValue<HTMLInputElement>>,
) {
  const listboxRef = useRef();
  const _triggerRef = useRef<FocusableRefValue<HTMLElement>>();
  const triggerRef = useFocusableRef(ref) || _triggerRef;
  // @ts-expect-error these ref typings are wonk
  const unwrappedTriggerRef = useUnwrapDOMRef(triggerRef);

  const onOpenChange = useCallback(
    $isOpen => {
      if ($isOpen && onOpen) onOpen();
      if (!$isOpen && onClose) onClose();
    },
    [onClose, onOpen],
  );

  function onSelectionChange(key?: Key) {
    if (onChange) {
      const item = state.collection.getItem(key);

      // here we make sure to pass as null when falsey, so that Select mode does not change from controlled to uncontrolled
      onChange(item ? item.props.value : null);
    }
  }

  const idAssigner = useMemo(() => createItemIdAssigner(), []);
  const optionsWithids = useMemo(() => idAssigner(options), [idAssigner, options]);

  const [disabledKeys, setDisabledKeys] = useState([]);

  // Create state based on the incoming props
  const state = useSelectState({
    selectedKey: value,
    defaultSelectedKey: defaultValue,
    onSelectionChange,
    isDisabled,
    placeholder,
    items: optionsWithids,
    children: generateListChildren,
    onOpenChange,
    disabledKeys,
  });

  const computedDisabledKeys = useCollectionKeyAccumulator(state.collection, 'isDisabled', true);
  React.useEffect(() => {
    setDisabledKeys(computedDisabledKeys);
  }, [computedDisabledKeys]);

  const clearSelection = useCallback(() => {
    if (isClearable) {
      state.setSelectedKey(null);
    }
  }, [isClearable, state]);

  const { triggerProps, menuProps } = useSelect(
    {
      selectedKey: value,
      defaultSelectedKey: defaultValue,
      isDisabled,
      disallowEmptySelection: true,
      placeholder,
      items: optionsWithids,
      children: generateListChildren,
      'aria-label': props['aria-label'],
    },
    state,
    unwrappedTriggerRef,
  );

  /**
   * Menu list items have a fixed height and padding, we can use
   * this information to adjust menu location so that active item
   * is focused on center, where the user's mouse is.
   *
   * This approach does not take <Section /> into account, or overflow / scrolling. Depending on need, we
   * might want to put effort into a more robust implementation at a later date.
   */
  const [buttonWidth] = useSize(unwrappedTriggerRef);
  const selectedIndex = state.selectedItem?.index || 0;
  const offset = useMemo(() => computePopoverOffset(size, options, selectedIndex), [size, options, selectedIndex]);
  const crossOffset = size === 'sm' ? -21 : -17;

  const triggerElem = (
    <FieldButton
      {...triggerProps}
      size={size}
      disabled={isDisabled}
      placeholder={placeholder}
      onClear={!isDisabled && isClearable ? clearSelection : undefined}
      appearance={appearance}
      w="full"
      // @ts-expect-error these ref typings are wonk
      ref={triggerRef}
      icon={icon}
    >
      {state.selectedItem
        ? triggerTextPrefix
          ? `${triggerTextPrefix}${state.selectedItem.rendered}`
          : state.selectedItem.rendered
        : undefined}
    </FieldButton>
  );

  return (
    <Box className="sl-select" pos="relative" flexGrow={flexGrow} flex={flex} w={w}>
      <HiddenSelect state={state} triggerRef={unwrappedTriggerRef} name={props.name} />

      <Popover
        triggerRef={unwrappedTriggerRef}
        scrollRef={listboxRef}
        isOpen={state.isOpen}
        onClose={state.close}
        offset={offset}
        crossOffset={crossOffset}
        placement="bottom left"
        shouldFlip={true}
        renderTrigger={triggerElem}
        appearance="minimal"
        type="listbox"
        isNonModal
      >
        <ListBoxPopup
          {...menuProps}
          ref={listboxRef}
          options={optionsWithids}
          state={state}
          minW={buttonWidth + Math.abs(crossOffset) + 4}
        />
      </Popover>
    </Box>
  );
}

const _Select = forwardRef(Select) as (
  props: SelectProps & { ref?: React.MutableRefObject<FocusableRefValue<HTMLInputElement>> },
) => React.ReactElement;
export { _Select as Select };

const ListBoxPopup = forwardRef(
  (
    {
      state,
      minW,
      options,
      ...otherProps
    }: Pick<SelectProps, 'options'> & { state: SelectState<object>; w?: WidthVals; minW?: number },
    ref: RefObject<HTMLDivElement>,
  ) => {
    const isDark = useThemeIsDark();

    // Get props for the listbox
    const { listBoxProps } = useListBox(
      {
        autoFocus: 'first',
        shouldFocusWrap: true,
        selectionMode: 'single',
        items: options,
        disallowEmptySelection: true,
        ...otherProps,
      },
      state,
      ref,
    );

    // pull color out, not needed and conflicting types
    const { color, ...restProps } = mergeProps(listBoxProps, otherProps);

    const pointerInteraction = getInteractionModality() === 'pointer';

    return (
      <>
        <DismissButton onDismiss={state.close} />

        <Box
          {...restProps}
          ref={ref}
          bg={isDark ? 'canvas-dialog' : 'canvas-pure'}
          style={{ minWidth: minW, maxHeight: 'inherit' }}
          py={2}
          className={cn('sl-menu', { 'sl-menu--pointer-interactions': pointerInteraction })}
          cursor
          overflowY="auto"
          noFocusRing
        >
          {[...state.collection].map(item => {
            const { type } = item;

            if (type === 'item') {
              return <SelectItem key={item.key} item={item} state={state} />;
            }

            if (type === 'section') {
              return <Section key={item.key} item={item} state={state} />;
            }

            if (type === 'placeholder') {
              return (
                <div
                  // aria-selected isn't needed here since this option is not selectable.
                  role="option"
                >
                  no items to select
                </div>
              );
            }
          })}
        </Box>

        <DismissButton onDismiss={state.close} />
      </>
    );
  },
);

function Divider() {
  const {
    separatorProps: { color, ...separatorProps },
  } = useSeparator({ elementType: 'div' });

  return <Box my={2} h="px" bg="canvas-200" {...separatorProps} />;
}

function Section({ item: section, state }: { item: Node<object>; state: SelectState<object> }) {
  const {
    itemProps,
    headingProps: { color: color1, ...headingProps },
    groupProps,
  } = useListBoxSection({
    heading: section.rendered,
    'aria-label': section['aria-label'],
  });

  return (
    <>
      {/* If the section is not the first, add a separator element. */}
      {section.key !== state.collection.getFirstKey() && <Divider />}

      <div {...itemProps}>
        {section.rendered && (
          <Box {...headingProps} pl={3} pt={0.5} pb={1} textTransform="uppercase" color="light" cursor fontSize="sm">
            {section.rendered}
          </Box>
        )}

        <div {...groupProps}>
          {Array.from(section.childNodes).map(node => {
            let item;
            if (node.type === 'item') {
              item = <SelectItem key={node.key} item={node} state={state} />;
            } else {
              item = null;
            }

            if (node.wrapper) {
              item = node.wrapper(item);
            }

            return item;
          })}
        </div>
      </div>
    </>
  );
}

function SelectItem({ item, state }: { item: Node<object>; state: SelectState<object> }) {
  const ref = useRef();

  const { key } = item;
  const { selectionManager } = state;

  const isDisabled = state.disabledKeys.has(item.key);
  const isSelected = selectionManager.isSelected(item.key);
  const isFocused = selectionManager.focusedKey === key;

  const { optionProps } = useOption(
    {
      key: item.key,
      isDisabled,
      isSelected,
      shouldFocusOnHover: true,
    },
    state,
    ref,
  );

  let props = {};
  if (item.props.onPress) {
    props = mergeProps(optionProps, {
      onClick: () => {
        if (isDisabled) return;
        item.props.onPress();
        state.close();
      },
      onKeyUp: e => {
        if (e.key === 'Enter') {
          if (isDisabled) return;
          item.props.onPress();
          state.close();
        }
      },
      onKeyDown: null,
      onMouseDown: null,
      onPointerDown: null,
      onPointerUp: null,
    });
  } else {
    props = mergeProps(optionProps);
  }

  return (
    <Internal_MenuItemRow
      {...props}
      ref={ref}
      title={item.rendered}
      description={item.props.description}
      isSelected={!!isSelected}
      isDisabled={isDisabled}
      meta={item.props.meta}
      isFocused={isFocused}
    />
  );
}

function createItemIdAssigner() {
  let counter = 0;

  return function assignItemIds(items: SelectProps['options']): SelectProps['options'] {
    const itemsWithIds = [];

    for (const item of items) {
      if (isSelectSection(item)) {
        counter++;
        itemsWithIds.push({ id: counter, ...item });
      } else {
        itemsWithIds.push(item);
      }
    }

    return itemsWithIds;
  };
}

function generateListChildren(item: unknown) {
  if (isSelectSection(item)) {
    return (
      <SelectSection {...item}>
        {(item: unknown) => {
          if (isSelectOption(item)) {
            return <SelectOption {...item} />;
          }

          if (isSelectAction(item)) {
            return <SelectAction {...item} />;
          }
        }}
      </SelectSection>
    );
  }

  if (isSelectOption(item)) {
    return <SelectOption {...item} />;
  }

  if (isSelectAction(item)) {
    return <SelectAction {...item} />;
  }

  return null;
}

function computePopoverOffset(
  size: SelectProps['size'],
  options: Array<SelectItemProps | SelectSectionProps>,
  selectedIndex: number,
) {
  const baseOffset = size === 'sm' ? -32 : -37;
  if (selectedIndex === 0) {
    return baseOffset;
  }

  let offset = baseOffset;
  const iterator = options2ElementsIterator(options);
  let i = 0;
  for (const element of iterator) {
    if (element.type == 'separator') {
      offset -= 17;
    } else if (element.type === 'section-heading') {
      offset -= 22;
    } else {
      if (i++ === selectedIndex) {
        return offset;
      }
      offset -= estimateOptionHeight(element.option);
    }
  }
  return offset;
}

function* options2ElementsIterator(
  options: Array<SelectItemProps | SelectSectionProps>,
): IterableIterator<{ type: 'separator' } | { type: 'section-heading' } | { type: 'item'; option: SelectItemProps }> {
  let yieldSeparator = false;
  for (const option of options) {
    if (yieldSeparator) {
      yieldSeparator = false;
      yield { type: 'separator' };
    }
    if ('options' in option) {
      yield { type: 'section-heading' };
      yieldSeparator = true;
      for (const sectionOption of option.options) {
        yield { type: 'item', option: sectionOption };
      }
    } else {
      yield { type: 'item', option: option };
    }
  }
}

function estimateOptionHeight(option: SelectItemProps) {
  let height = 26;
  if (option.description) {
    height += 16;
    if (option.description.length >= 40) {
      height += 16;
    }
  }
  return height;
}

/**
 * Original credit: https://github.com/adobe/react-spectrum/blob/main/packages/%40react-aria/overlays/src/DismissButton.tsx
 *
 * Adapted to use our custom VisuallyHidden
 */

import * as React from 'react';

import { VisuallyHidden } from '../VisuallyHidden';

interface DismissButtonProps {
  /** Called when the dismiss button is activated. */
  onDismiss?: () => void;
}

/**
 * A visually hidden button that can be used to allow screen reader
 * users to dismiss a modal or popup when there is no visual
 * affordance to do so.
 */
export function DismissButton(props: DismissButtonProps) {
  let { onDismiss } = props;
  let onClick = () => {
    if (onDismiss) {
      onDismiss();
    }
  };

  return (
    <VisuallyHidden>
      <button tabIndex={-1} aria-label="Dismiss" onClick={onClick} />
    </VisuallyHidden>
  );
}

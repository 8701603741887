import { FocusRing } from '@react-aria/focus';
import { useTabPanel } from '@react-aria/tabs';
import { useCollection } from '@react-stately/collections';
import { ListCollection } from '@react-stately/list';
import { CollectionChildren } from '@react-types/shared';
import * as React from 'react';
import { useContext, useRef } from 'react';

import { OverflowVals, SpaceVals } from '../../enhancers';
import { Box } from '../Box';
import { TabsContext } from './TabsContext';

export type TabPanelsProps = {
  children: CollectionChildren<any>;

  /**
   * Padding val to apply to the TabPanels container. Defaults to `4`.
   */
  p?: SpaceVals;

  /**
   * Margin val to apply to the TabPanels container. Defaults to `0`.
   */
  m?: SpaceVals;

  /**
   * overflowY val to apply to the TabPanels container.
   */
  overflowY?: OverflowVals;
};

export function TabPanels({ p = 4, m, overflowY, ...props }: TabPanelsProps) {
  const { tabState, tabPanelProps: ctxTabPanelProps, tabsProps } = useContext(TabsContext);
  const { tabListState } = tabState;

  const ref = useRef();
  const { tabPanelProps } = useTabPanel({}, tabListState, ref);

  if (ctxTabPanelProps['aria-labelledby']) {
    tabPanelProps['aria-labelledby'] = ctxTabPanelProps['aria-labelledby'];
  }

  const factory = nodes => new ListCollection(nodes);
  const collection = useCollection(props, factory, { suppressTextValueWarning: true });
  const selectedItem = tabListState ? collection.getItem(tabListState.selectedKey) : null;

  const { orientation } = tabsProps;
  const { color, ...tabPanelPropsWithoutColor } = tabPanelProps;

  const py = orientation === 'vertical' ? undefined : p;
  const px = orientation === 'vertical' ? p : undefined;

  return (
    <FocusRing focusRingClass="sl-focus-ring">
      <Box {...tabPanelPropsWithoutColor} ref={ref} py={py} px={px} flex={1} m={m} overflowY={overflowY}>
        {selectedItem && selectedItem.props.children}
      </Box>
    </FocusRing>
  );
}
